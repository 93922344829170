import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAccountDetailsOutline,
  mdiContentCopy,
  mdiContentSaveOutline,
  mdiPencilOutline,
  mdiTrashCanOutline,
} from '@mdi/js';
import _, { get, head, omit } from 'lodash';
import { useSelector } from 'react-redux';

import {
  capitalizeFirstChar,
  textToClipboard,
} from '../../utils';
import InfoBlock from '../../components/InfoBlock';
import {
  isGetEntityFetching,
  isSelectedCanDelete,
  isSelectedEntityLoaded,
} from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import DeleteButton from '../../components/DeleteButton';
import AntButton from '../../components/antDesign/AntButton';
import antNotification from '../../utils/antNotification';
import EntityConstants from '../constants';

function EntityInfo({
  entity,
  getEntityText,
  getAffixText,
  getEntityColor,
  partition,
  deleteTreeNode,
  updateTreeCallback,
}) {
  const {
    requestCheckCanDeleteEntity,
    requestEntityUpdate,
    requestUpdateAffix,
    requestEntitySet,
  } = useContext(ApiContext);

  const { t } = useTranslation();

  const entityLoaded = useSelector(isSelectedEntityLoaded);
  const fetching = useSelector(isGetEntityFetching);
  const canDelete = useSelector(isSelectedCanDelete);

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState('');

  const type = _.get(entity, 'entity_type') ? 'entity' : 'affix';
  const itemType = _.get(entity, `${type}_type`, '');

  const typeTag = (
    <Tag className={`tag-${type === 'entity' ? 'gray' : 'red'}-light ml-3`}>
      {capitalizeFirstChar(type)}
    </Tag>
  );

  const itemTypeTag = (
    <Tag
      className={`tag-${type === 'entity'
        ? getEntityColor(entity)
        : 'gray'} ml-2`}
    >
      {capitalizeFirstChar(itemType)}
    </Tag>
  );

  const saveData = async () => {
    try {
      let currentValue = JSON.parse(value);

      // CHECK AND UPDATE  Creator perm, actor, Owner
      if (type === 'entity' && (
        currentValue?.owner !== entity?.owner
        || currentValue?.actor !== entity?.actor
        || JSON.stringify(currentValue?.creator_perms) !== JSON.stringify(entity?.creator_perms)
      )) {
        // console.log('------actor-------');
        const config = {
          data: {
            entity_uuid: entity.uuid,
            creator_perms: currentValue?.creator_perms,
            actor: currentValue?.actor,
            owner: currentValue?.owner,
          },
          partition,
          constants: ['REQUEST', 'SUCCESS', 'FAILURE'],
        };

        const res = await requestEntitySet(config);

        currentValue = { params: currentValue.params, ...omit(head(res), 'params') };
      }

      // CHECK AND UPDATE  Params
      const config = {
        data: {
          [type === 'affix' ? 'affix_uuid' : 'entity_uuid']: currentValue.uuid,
          [type === 'affix' ? 'entity_uuid' : 'parent']: type === 'affix' ? get(currentValue, 'entity_uuid') : get(currentValue, 'parent'),
          params: get(currentValue, 'params', {}),
        },
        partition,
        constants: ['UPDATE_REQUEST', EntityConstants.UPDATE_SUCCESS, 'UPDATE_FAILURE'],
      };
      let res;
      if (entity?.affix_type) {
        res = await requestUpdateAffix(config);
      } else {
        res = await requestEntityUpdate(config);
      }

      if (head(res)[type === 'affix' ? 'entity_uuid' : 'parent'] !== entity[type === 'affix' ? 'entity_uuid' : 'parent']) {
        updateTreeCallback(head(res), true);
      } else {
        updateTreeCallback(head(res));
      }

      setValue(JSON.stringify(head(res), null, 4));

      antNotification.success('Success');

      setIsEdit(false);
    } catch {
      antNotification.error('JSON is not valid');
    }
  };

  const toggleEditOrSave = async () => {
    if (isEdit) {
      saveData();
    } else {
      setIsEdit(true);
    }
  };

  const onChange = (e) => setValue(e.target.value);

  const initFunc = () => {
    switch (type) {
      case 'entity':
        requestCheckCanDeleteEntity(entity.uuid, partition);
        break;
      case 'affix':
        requestCheckCanDeleteEntity(entity.entity_uuid, partition);
        break;
      default:
        break;
    }

    setValue(JSON.stringify(entity, null, 4));
  };

  useEffect(() => {
    initFunc();
    if (isEdit) {
      setIsEdit(false);
    }
  }, [entity.uuid]);

  return (
    <Spin
      spinning={fetching}
    >
      <Card className="card border-blue">
        <Row gutter={[16, 0]} wrap={false}>
          <Col flex="none">
            <h4 className="header-primary">
              {type === 'entity' ? capitalizeFirstChar(t(
                'ew.headers.entity_info',
                'entity info',
              )) : capitalizeFirstChar(t(
                'ew.headers.affix_info',
                'affix info',
              ))}
            </h4>
          </Col>
          <Col flex="auto" className="d-flex justify-content-end">
            <Space size={[5, 1]} wrap className="justify-content-end">
              <AntButton
                className="mr-1 btnPrimary"
                onClick={toggleEditOrSave}
                disabled={get(entity, 'entity_type') === 'partition'}
                size="small"
                type="primary"
              >
                <Icon
                  size={1}
                  className="mr-1"
                  path={isEdit ? mdiContentSaveOutline : mdiPencilOutline}
                />

                {capitalizeFirstChar(t(`buttons.${isEdit ? 'save' : 'edit'}`))}
              </AntButton>

              <DeleteButton
                targetType={get(entity, 'entity_type') === 'partition' ? 'partition' : type}
                uuid={entity.uuid}
                partition={partition}
                icon={(
                  <Icon
                    path={mdiTrashCanOutline}
                    size={1}
                    className="mr-1"
                  />
                )}
                title={capitalizeFirstChar(t(`ew.headers.remove_${type}`, `remove ${type}`))}
                disabled={!canDelete}
                onSuccess={() => deleteTreeNode(entity.uuid)}
              />

            </Space>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col span={24} className="d-flex align-items-center">
            <h3 className="header-primary m-0">
              {type === 'entity'
                ? getEntityText(entity)
                : getAffixText(entity)}
            </h3>
            {typeTag}
            {itemTypeTag}
          </Col>
          <Col span={24} className="d-flex align-items-center mt-2">
            <h5 className="header-primary">
              UUID:
              {' '}
              {entity.uuid}
            </h5>
            <Button
              className="button-primary-link ml-2"
              size="small"
              onClick={() => textToClipboard(entity.uuid)}
            >
              <Icon path={mdiContentCopy} size={1} />
            </Button>
          </Col>
        </Row>
        <hr className="my-4" />
        {!entityLoaded && !fetching && (
          <Alert
            className="mb-2"
            message={capitalizeFirstChar(t(
              'ew.messages.entity_dont_loaded',
              'entity dont loaded',
            ))}
            type="error"
          />
        )}
        <Form
          layout="vertical"
          autoComplete="off"
        >
          <InfoBlock
            iconPath={mdiAccountDetailsOutline}
            title={capitalizeFirstChar(t(
              'ew.headers.detail',
              'detail info',
            ))}
          >
            {isEdit && (
              <Alert
                className="mb-3"
                message="Read me"
                description={(
                  <Row gutter={[10, 10]}>
                    <Col>
                      Be careful with updating fields as this can lead to undefined consequences.
                      You can only update:
                    </Col>
                    <Col><Tag color="#108ee9">params</Tag></Col>
                    <Col><Tag color="#87d068">{type === 'entity' ? 'parent' : 'entity_uuid'}</Tag></Col>
                    {type === 'entity' && (
                      <>
                        <Col><Tag color="#2db7f5">actor</Tag></Col>
                        <Col><Tag color="#f50">owner</Tag></Col>
                        <Col><Tag color="#f7d865">creator_perms</Tag></Col>
                      </>
                    )}
                  </Row>
                )}
                type="info"
                showIcon
              />
            )}

            <Row gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  label={capitalizeFirstChar(t(
                    'ew.headers.content',
                    'content',
                  ))}
                >
                  <Input.TextArea
                    value={value}
                    onChange={onChange}
                    autoSize={{
                      minRows: 3,
                      maxRows: 20,
                    }}
                    placeholder={capitalizeFirstChar(t(
                      'ew.placeholders.enter',
                      { value: '$t(ew.headers.content)' },
                    ))}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
          </InfoBlock>
        </Form>
      </Card>
    </Spin>
  );
}

export default EntityInfo;

EntityInfo.propTypes = {
  deleteTreeNode: PropTypes.func.isRequired,
  entity: PropTypes.instanceOf(Object).isRequired,
  getAffixText: PropTypes.func.isRequired,
  getEntityColor: PropTypes.func.isRequired,
  getEntityText: PropTypes.func.isRequired,
  partition: PropTypes.string.isRequired,
};
