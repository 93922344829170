import {
  Button, Card, Col, Dropdown, Input, Row,
} from 'antd';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../api/ApiContextProvider';
import { getPartitionsByName } from '../../config/selectors';
import { entityLtreeCheck, entityUniqClean } from '../actions/AdminActions';
import antNotification from '../../utils/antNotification';

function EntityServiceCard() {
  const dispatch = useDispatch();

  const { requestDeleteEntity } = useContext(ApiContext);

  const namesOfPartitions = useSelector(getPartitionsByName) || {};

  const [selectedPartition, setSelectedPartition] = useState('PM');
  const [ltreeInvalidItems, setLtreeInvalidItems] = useState(null);
  const [inputtedUUID, setInputtedUUID] = useState('');
  const [inputtedKey, setInputtedKey] = useState('');

  const entityUniqCleanFunc = () => {
    dispatch(entityUniqClean({
      partition: selectedPartition,
    })).then(() => {
      antNotification.success('success');
    }).catch(() => {
      antNotification.error('Error');
    });
  };

  const singleEntityUniqCleanFunc = () => {
    dispatch(entityUniqClean({
      partition: selectedPartition,
      [inputtedUUID ? 'entityUUID' : 'key']: inputtedUUID || inputtedKey,
    })).then(() => {
      antNotification.success('success');
      setInputtedUUID('');
    });
  };

  const uniqCleanFromHashesAndEntityFunc = () => {
    singleEntityUniqCleanFunc()
    requestDeleteEntity(inputtedUUID, selectedPartition);
  }

  const entityLtreeCheckFunc = () => {
    dispatch(entityLtreeCheck(selectedPartition)).then((res) => {
      setLtreeInvalidItems(res);
    });
  };

  const selectMenu = (name) => {
    setSelectedPartition(name);
  };

  const removeInvalidsLtree = () => {
    requestDeleteEntity(ltreeInvalidItems.map((item) => item?.uuid), selectedPartition);
    entityLtreeCheckFunc();
  };

  const onChangeUniq = (e) => {
    setInputtedUUID(e.target.value);
  };

  const onChangeKey = (e) => {
    setInputtedKey(e.target.value);
  };


  const items = Object.keys(namesOfPartitions).map((item, i) => ({
    label: item,
    key: item,
    onClick: ({ key }) => selectMenu(key),
  }));

  const ltreeInvalidItemsLength = ltreeInvalidItems?.length;
  return (
    <Card title="Entity service">
      <Row>
        <Col span={5}>
          <span>Selected partition</span>
        </Col>
        <Col>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
          >
            <Button>{selectedPartition}</Button>
          </Dropdown>
        </Col>
      </Row>
      <hr />
      <Row className="mt-3">
        <Col span={5}>
          <span>Uniq clean</span>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex">
          <Input
            placeholder="UUID"
            onChange={onChangeUniq}
            value={inputtedUUID}
          />
          <Input
            placeholder="Key"
            onChange={onChangeKey}
            value={inputtedKey}
          />
        </Col>
      </Row>
      <Row className={'mt-3'}>
        <Col>
          <Button
            type="primary"
            className="ml-2"
            onClick={singleEntityUniqCleanFunc}
          >
            Clean uuid from table of hashes
          </Button>
          <Button
            type="primary"
            className="ml-2"
            onClick={uniqCleanFromHashesAndEntityFunc}
          >
            Clean uuid from table of hashes and entity
          </Button>
          <Button
            type="primary"
            className="ml-2"
            onClick={entityUniqCleanFunc}
          >
            Clean all unique table hashes
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={5}>
          <span>Ltree check</span>
        </Col>
        <Col span={5} className="d-flex ">
          <Button
            type="primary"
            className="ml-2"
            onClick={entityLtreeCheckFunc}
          >
            Check entity ltree
          </Button>
        </Col>
        <>
          {ltreeInvalidItemsLength > 0 && (
            <Col>
              <Button onClick={removeInvalidsLtree} danger>Remove all invalids ltree</Button>
            </Col>
          )}
          {ltreeInvalidItems && (
            <Col className={`${ltreeInvalidItemsLength > 0 ? 'text-red' : 'text-success'} ml-5 d-flex align-items-center`}>
              <span>Count of invalid ltree:</span>
              <span className="ml-2">
                {ltreeInvalidItemsLength}
              </span>
            </Col>
          )}
        </>
      </Row>
      {ltreeInvalidItemsLength > 0 && (
        <>
          <Row className="mt-5">
            <Col span={8}>Entity type</Col>
            <Col span={8}>UUID</Col>
            <Col span={8}>Parent</Col>
            {/* <Col span={2}>Action</Col> */}
          </Row>
          <Row className="mt-4">
            {ltreeInvalidItems.map((item) => (
              <>
                <Col span={8}>{item?.entity_type}</Col>
                <Col span={8}>{item?.uuid}</Col>
                <Col span={8}>{item?.parent}</Col>
                {/* <Col span={2}><Button danger>Remove</Button></Col> */}
              </>
            ))}
          </Row>
        </>
      )}
    </Card>
  );
}

export default EntityServiceCard;
