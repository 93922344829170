import React, { memo } from 'react';
import {
  Col,
  Row,
} from 'antd';
import { Link } from 'react-router-dom';

import LocaleSwitcher from './LocaleSwitcher';

import ProfileMenu from './ProfileMenu';
import InterfacesListDropDown54Origins from '../54origins/components/publicInterface54Origins/InterfacesListDropDown54Origins';
import InterfacesListModal54Origins
  from '../54origins/components/publicInterface54Origins/InterfacesListModal54Origins';
import { configUrlEntity } from '../api/appConfig';

function Header() {
  return (
    <header className="dt-header">
      <div className="dt-header__container">
        <div className="dt-brand">
          <span className="dt-brand__logo">
            <Link className="dt-brand__logo-link" to="/view">
              Entity Viewer
            </Link>
          </span>
        </div>
        <Row className="d-flex align-items-stretch justify-content-end w-100">
          <Col>
            <InterfacesListModal54Origins
              url={configUrlEntity}
              sessionTokenName="Entity"
              className="h-100"
            />
          </Col>
          <Col>
            <LocaleSwitcher />
          </Col>
          <Col>
            <ProfileMenu />
          </Col>
        </Row>
      </div>
    </header>
  );
}

export default memo(Header);
