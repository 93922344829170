import { Col, Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StickyBox from 'react-sticky-box';
import PermissionsStartPage from '../../permissions/PermissionsStartPage';
import paramsURL from '../../utils/ParamsURL';
import EntityInfo from './EntityInfo';

export default function EntityInfoAndPermissions({
  entity,
  partition,
  getEntityText,
  getAffixText,
  getEntityColor,
  deleteTreeNode,
  updateTreeCallback,
}) {
  const dataFromUrl = useSelector((state) => paramsURL?.get(state));
  const { activeActorPerm } = dataFromUrl;

  const [currentTab, setCurrentTab] = useState('info');
  const TabItems = [
    {
      label: 'Info card',
      key: 'info',
      children: (
        <EntityInfo
          entity={entity}
          partition={partition}
          getEntityText={getEntityText}
          getAffixText={getAffixText}
          getEntityColor={getEntityColor}
          deleteTreeNode={deleteTreeNode}
          updateTreeCallback={updateTreeCallback}
        />
      ),
    },
    {
      label: 'Permissions card',
      key: 'permissions',
      disabled: entity?.affix_type,
      children: (
        <PermissionsStartPage
          entityUUID={entity?.uuid}
          partitionType={partition}
          nameSectionPermissions=""
        />
      ),
    },
  ];

  useEffect(() => {
    if (entity?.uuid && currentTab !== 'info') {
      setCurrentTab('info');
    }
  }, [entity?.uuid]);

  return (
    <Col span={activeActorPerm ? 20 : 10} className="ml-4">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <Tabs
          destroyInactiveTabPane
          type="card"
          activeKey={currentTab}
          items={TabItems}
          onChange={setCurrentTab}
        />
      </StickyBox>
    </Col>
  );
}
