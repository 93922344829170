import _ from 'lodash';

export const getSelectedEntity = (state) => _.get(
  state,
  'entities.selected.data',
  null,
);

export const isSelectedEntityLoaded = (state) => _.get(
  state,
  'entities.selected.loaded',
  false,
);

export const isGetEntityFetching = (state) => _.get(
  state,
  'entities.fetching',
  false,
);

export const isSelectedCanDelete = (state) => _.get(
  state,
  'entities.selected.can_delete',
  false,
);
