import { Modal, Switch } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash';
import { PermissionsContext } from './context';
import { capitalizeFirstChar } from '../utils';

function SwitchComponent({
  item,
  changeSwitchCallback,
  resetSwitchBool,
  cardName,
}) {
  const { t } = useTranslation();
  const {
    checkNotSavedPermissions,
  } = useContext(PermissionsContext);

  const nameGroup = get(item, 'uinfo.group_name', '');
  const isAdmin = nameGroup === 'ADMIN' || get(item, 'is_admin');
  const isRoot = get(item, 'is_root');
  const userInBan = get(item, 'userInBan');
  const [state, setState] = useState(item);

  const showConfirmForAffixCreator = (item, newData, typePerm) => {
    Modal.confirm({
      // title: capitalizeFirstChar(
      //   t('wms.modals.headers.removing_process', 'removing process'),
      // ),
      width: '600px',
      content: capitalizeFirstChar(t('labels.assigned_acd_label')),
      okText: capitalizeFirstChar(
        t('buttons.removing_process.yes', 'yes, I am'),
      ),
      okType: 'danger',
      cancelText: capitalizeFirstChar(
        t('buttons.removing_process.no', 'no, I\'m not'),
      ),
      open: false,
      centered: true,
      maskClosable: true,
      okButtonProps: { className: 'btnDanger' },
      cancelButtonProps: { className: 'btnPrimary-outline' },

      onOk() {
        const data = { ...newData, recursive: true };
        setState(newData);
        changeSwitchCallback(item, data, typePerm);
      },
      onCancel() {
        const data = { ...newData, recursive: false };
        setState(newData);
        changeSwitchCallback(item, data, typePerm);
      },
    });
  };
  // if(cardName === 'creatorCard' )  console.log('state',state, 'item',item);

  const viewSwitch = (label, typePerm) => {
    const checked = isRoot || isAdmin ? true : userInBan ? false : get(state, typePerm);
    const disabled = isRoot || isAdmin || userInBan || get(item, 'disabled');

    const onChecked = (e) => {
      if (checkNotSavedPermissions && checkNotSavedPermissions(cardName)) {
        return;
      }

      const newData = {
        ...state,
        [typePerm]: e,
      };

      if (typePerm === 'affix_creator_delete') {
        showConfirmForAffixCreator(item, newData, typePerm);
      } else {
        setState(newData);
        changeSwitchCallback(item, newData, typePerm);
      }
    };

    return (
      <div className="container-switch">
        <div>{label}</div>
        <Switch
          disabled={disabled}
          onChange={onChecked}
          checked={checked}
          key={`${label}-${typePerm}`}
          // className="switch-primary"
          checkedChildren="ON"
          unCheckedChildren="OFF"
          size="small"
        />
      </div>
    );
  };

  const stopPropagation = (e) => e.stopPropagation();

  useEffect(() => {
    if (!isAdmin) {
      setState(item);
    }
  }, [
    get(item, 'create'),
    get(item, 'read'),
    get(item, 'update'),
    get(item, 'delete'),
    get(item, 'list'),
    get(item, 'set'),
    get(item, 'affix_creator_delete'),
  ]);

  useEffect(() => {
    if (resetSwitchBool) {
      setState(item);
    }
  }, [resetSwitchBool]);

  return (
    <div
      className="d-flex align-items-center justify-content-center "
      onClick={stopPropagation}
    >
      {viewSwitch('C', 'create')}
      {viewSwitch('R', 'read')}
      {viewSwitch('U', 'update')}
      {viewSwitch('D', 'delete')}
      {viewSwitch('L', 'list')}
      {viewSwitch('S', 'set')}
      {cardName === 'creatorCard' && viewSwitch('ACD', 'affix_creator_delete')}
    </div>
  );
}

// SwitchComponent.propTypes = {
//   item: PropTypes.object,
//   changeSwitchCallback: PropTypes.func,
//   resetSwitchBool: PropTypes.bool,
//   cardName: PropTypes.string,
// };

export default SwitchComponent;
