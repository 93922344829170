// noinspection JSValidateTypes

import Cookies from 'universal-cookie';
import _ from 'lodash';
import { history } from '../../history';

class AuthHelper {
  static getToken() {
    const cookies = new Cookies();

    return cookies.get('Entity');
  }

  static access() {
    const access = localStorage.getItem('access') || false;
    return !!access;
  }

  static authorizedAccess(data) {
    localStorage.setItem('access', data);
  }

  static isAuthorised() {
    const cookies = new Cookies();
    const authToken = cookies.get('Entity');
    return !!(
      authToken && authToken !== 'undefined'
    );
  }

  static removeSession() {
    try {
      const cookies = new Cookies();
      const allCookies = cookies.getAll();
      _.map(allCookies, (value, key) => {
        if (key === 'session') {
          cookies.remove('session', { path: '/' });
        }
      });
    } catch (e) {
      // do nothing
    }
  }
}

export default AuthHelper;
