import React from 'react';
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Tag,
} from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  mdiAccountCircleOutline,
  mdiLogoutVariant,
  mdiMenuDown,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserAvatar from './UserAvatar';

import { getProfileInfo } from '../profile/selectors';
import { capitalizeFirstChar } from '../utils';
import { logoutAndClearAllCookies } from '../54origins/utils54origins';

function ProfileMenu() {
  const profileInfo = useSelector(getProfileInfo);
  const { t } = useTranslation();

  const getUserInfo = (key) => _.get(profileInfo, `uinfo.${key}`, '');

  const profileMenu = (
    <Menu>
      <Menu.Item
        key="profile"
        className="header-dropdown-item pl-3"
      >
        <Link to="/profile">
          <Icon path={mdiAccountCircleOutline} size={1.5} className="mr-3" />
          {capitalizeFirstChar(t('app.headers.profile', 'profile'))}
          {_.get(profileInfo, 'root', false) && (
            <Tag className="tag-purple ml-2">
              {t('auth.headers.root', 'root').toUpperCase()}
            </Tag>
          )}
        </Link>
      </Menu.Item>
      <Menu.Item
        key="logout"
        className="header-dropdown-item item-red pl-3"
      >
        <Link to="/login" onClick={() => logoutAndClearAllCookies()}>
          <Icon path={mdiLogoutVariant} size={1.5} className="mr-3" />
          {capitalizeFirstChar(t('app.headers.log_out', 'log out'))}
        </Link>
      </Menu.Item>
    </Menu>
  );

  if (!profileInfo.uuid) {
    return null;
  }

  return (
    <div className="header-dropdown-wrapper h-100">
      <Dropdown
        trigger={['click']}
        overlay={profileMenu}
        placement="bottomCenter"
        className="h-100"
      >
        <Row className="cursor-pointer">
          <Col className="d-flex align-items-center">
            {profileInfo && (
              <UserAvatar
                userUUID={profileInfo.uuid}
                userFirstName={getUserInfo('first_name')}
                userLastName={getUserInfo('last_name')}
                size={32}
              />
            )}
          </Col>
          <Col className="d-flex align-items-center ml-3">
            <h4 className="m-0" style={{ userSelect: 'none' }}>
              {getUserInfo('first_name')}
              {' '}
              {getUserInfo('last_name')}
            </h4>
          </Col>
          <Col className="d-flex align-items-center">
            <Icon path={mdiMenuDown} size={1.5} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default ProfileMenu;
