import { createRoot } from 'react-dom/client';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CookiesProvider } from 'react-cookie';
import { Spin } from 'antd';
import store from './store';
import { history } from './history';
import App from './components/App';

import './fonts/noir-pro/stylesFonts.css';
import './index.css';
import './styles/main.css';

import './styles/custom.css';
// import './styles/json.scss';
import './index.scss';
import './i18n';
import 'moment/locale/ru';

import ApiContextProvider from './api/ApiContextProvider';

const root = createRoot(document.getElementById('root'));

root.render(
  <CookiesProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ApiContextProvider>
          <Suspense fallback={<Spin />}>
            <div className="dt-sidebar--fixed dt-header--fixed dt-layout--full-width font-dancing-script">
              <Router history={history}>
                <App />
              </Router>
            </div>
          </Suspense>
        </ApiContextProvider>
      </ConnectedRouter>
    </Provider>
  </CookiesProvider>,
);
