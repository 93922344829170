import keyMirror from 'keymirror';

const EntityConstants = keyMirror({
  GET_ENTITIES_REQUEST: null,
  GET_ENTITIES_SUCCESS: null,
  GET_ENTITIES_FAILURE: null,

  GET_AFFIXES_REQUEST: null,
  GET_AFFIXES_SUCCESS: null,
  GET_AFFIXES_FAILURE: null,

  GET_ENTITY_REQUEST: null,
  GET_ENTITY_SUCCESS: null,
  GET_ENTITY_FAILURE: null,

  UPDATE_ENTITY_PARENT_REQUEST: null,
  UPDATE_ENTITY_PARENT_SUCCESS: null,
  UPDATE_ENTITY_PARENT_FAILURE: null,

  CHECK_CAN_DELETE_ENTITY_REQUEST: null,
  CHECK_CAN_DELETE_ENTITY_SUCCESS: null,
  CHECK_CAN_DELETE_ENTITY_FAILURE: null,

  DELETE_ENTITY_REQUEST: null,
  DELETE_ENTITY_SUCCESS: null,
  DELETE_ENTITY_FAILURE: null,

  DELETE_AFFIX_REQUEST: null,
  DELETE_AFFIX_SUCCESS: null,
  DELETE_AFFIX_FAILURE: null,

  SET_SELECTED_ENTITY: null,
  RESET_SELECTED_ENTITY: null,

  GET_AFFIX_REQUEST: null,
  GET_AFFIX_SUCCESS: null,
  GET_AFFIX_FAILURE: null,

  UPDATE_SUCCESS: null,

  SET_FETCHING_FOR_ACTORS_LIST: null,
});

export default EntityConstants;
