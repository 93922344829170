import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { useDispatch } from 'react-redux';
import _, { isEmpty } from 'lodash';
import axiosRequest from './api';
import ProfileConstants from '../profile/constants';
import ConfigConstants from '../config/constants';
import EntityConstants from '../entityTree/constants';
import {replaceDomainByService} from "./appConfig";

export const ApiContext = createContext({});

function ApiContextProvider({
  children,
}) {
  const dispatch = useDispatch();

  // CONFIG
  const requestGetPublicInterfaces = () => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'public_interfaces/bulk',
          [
            ConfigConstants.GET_PUBLIC_INTERFACES_REQUEST,
            ConfigConstants.GET_PUBLIC_INTERFACES_SUCCESS,
            ConfigConstants.GET_PUBLIC_INTERFACES_FAILURE,
          ],
          {
            service_domain: replaceDomainByService('entity-viewer'),
          },
          {
            service: 'auth',
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestGetPartitions = () => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'partition/get',
          [
            ConfigConstants.GET_PARTITIONS_REQUEST,
            ConfigConstants.GET_PARTITIONS_SUCCESS,
            ConfigConstants.GET_PARTITIONS_FAILURE,
          ],
          {},
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestCreatePartition = (data) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'partition/create',
          [
            'CREATE_PARTITION_REQUEST',
            'CREATE_PARTITION_SUCCESS',
            'CREATE_PARTITION_FAILURE',
          ],
          data,
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestDeletePartition = (partition_uuid, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'partition/delete',
          [
            'CREATE_PARTITION_REQUEST',
            'CREATE_PARTITION_SUCCESS',
            'CREATE_PARTITION_FAILURE',
          ],
          { partition_uuid },
          {
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
            partition,
          },
        ),
      );
    })
  );

  // ENTITY
  const getListOfEntities = ({
    data,
    partition = 'PM',
    constants,
    doNotCheckError = false,
    aborting = false,
    cancelToken,
  }) => new Promise((onSuccess, onFailure) => {
    const onSuccessData = (res = []) => onSuccess(
      {
        entity_type: _.get(data, 'entity_type'),
        ...res,
      },
    );

    dispatch(
      axiosRequest.post(
        'entity/list',
        constants,
        data,
        {
          partition,
          onSuccess: onSuccessData,
          onFailure,
          doNotCheckError,
          aborting,
          cancelToken,
        },
      ),
    );
  });

  const getListAndReadEntities = ({
    data,
    partition,
    constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
    doNotCheckError = false,
    aborting = false,
    cancelToken,
    requestOptions,
  }) => {
    const configList = {
      data,
      partition,
      constants: [constants[0], '', ''],
    };

    return new Promise((onSuccess, onFailure) => {
      getListOfEntities(configList)
        .then((listParams) => {
          if (isEmpty(listParams.entity_uuids)) {
            const action = typeof constants[1] === 'object'
              ? constants[1]
              : { type: constants[1], payload: [] };
            dispatch(action);
            onSuccess({ data: [], total: 0 });
            return;
          }

          const onSuccessRead = (res) => onSuccess({ data: res, total: _.get(listParams, 'total') });

          dispatch(
            axiosRequest.post(
              'entity/read',
              ['', constants[1], constants[2]],
              listParams,
              requestOptions || {
                partition,
                onSuccess: onSuccessRead,
                onFailure,
                doNotCheckError,
                aborting,
                cancelToken,
              },
            ),
          );
        })
        .catch((error) => {
          console.log(`${constants[2]}: `, 'error', error);
          onFailure(error);
        });
    });
  };

  const requestGetEntity = (uuid, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'entity/read',
          [
            EntityConstants.GET_ENTITY_REQUEST,
            EntityConstants.GET_ENTITY_SUCCESS,
            EntityConstants.GET_ENTITY_FAILURE,
          ],
          { entity_uuid: uuid },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestUpdateEntityParent = (uuid, newParent, partition) => (
    new Promise((resolve, reject) => {
      const name = _.isArray(uuid)
        ? 'entity_uuids'
        : 'entity_uuid';

      dispatch(
        axiosRequest.post(
          'entity/update',
          [
            EntityConstants.UPDATE_ENTITY_PARENT_REQUEST,
            EntityConstants.UPDATE_ENTITY_PARENT_SUCCESS,
            EntityConstants.UPDATE_ENTITY_PARENT_FAILURE,
          ],
          {
            [name]: uuid,
            parent: newParent,
          },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestCheckCanDeleteEntity = (uuid, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'utility/eperm/check',
          [
            EntityConstants.CHECK_CAN_DELETE_ENTITY_REQUEST,
            EntityConstants.CHECK_CAN_DELETE_ENTITY_SUCCESS,
            EntityConstants.CHECK_CAN_DELETE_ENTITY_FAILURE,
          ],
          {
            entity_uuid: uuid,
            perm: 'delete',
          },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestDeleteEntity = (uuid, partition) => (
    new Promise((resolve, reject) => {
      const name = _.isArray(uuid)
        ? 'entity_uuids'
        : 'entity_uuid';
      dispatch(
        axiosRequest.post(
          'entity/delete',
          [
            EntityConstants.DELETE_ENTITY_REQUEST,
            EntityConstants.DELETE_ENTITY_SUCCESS,
            EntityConstants.DELETE_ENTITY_FAILURE,
          ],
          {
            [name]: uuid,
          },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestEntityUpdate = ({
    data,
    constants,
    partition,
    aborting = false,
    cancelToken,
    signal,
  }) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/update',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            aborting,
            cancelToken,
            signal,
          },
        ),
      );
    })
  );

  const requestEntityCreate = ({
    data,
    constants,
    partition,
  }) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/create',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
          },
        ),
      );
    })
  );

  const requestEntitySet = ({
    data,
    constants,
    partition,
  }) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/set',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
          },
        ),
      );
    })
  );

  // AFFIXES

  const getListOfAffix = ({
    data,
    partition = 'PM',
    constants,
    doNotCheckError = false,
    aborting = false,
    cancelToken,
  }) => new Promise((onSuccess, onFailure) => {
    const onSuccessData = (res = []) => onSuccess(
      {
        affix_type: _.get(data, 'affix_type'),
        ...res,
      },
    );

    dispatch(
      axiosRequest.post(
        'entity/affix/list',
        constants,
        data,
        {
          partition,
          onSuccess: onSuccessData,
          onFailure,
          doNotCheckError,
          aborting,
          cancelToken,
        },
      ),
    );
  });

  const getListAndReadAffix = ({
    data,
    partition,
    constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
    doNotCheckError = false,
    aborting = false,
    cancelToken,
    requestOptions,
  }) => {
    const configList = {
      data,
      partition,
      constants: [constants[0], '', ''],
    };

    return new Promise((onSuccess, onFailure) => {
      getListOfAffix(configList)
        .then((listParams) => {
          if (isEmpty(listParams.affix_uuids)) {
            const action = typeof constants[1] === 'object'
              ? constants[1]
              : { type: constants[1], payload: [] };
            dispatch(action);
            onSuccess({ data: [], total: 0 });
            return;
          }

          const onSuccessRead = (res) => onSuccess({ data: res, total: _.get(listParams, 'total') });

          dispatch(
            axiosRequest.post(
              'entity/affix/read',
              ['', constants[1], constants[2]],
              listParams,
              requestOptions || {
                partition,
                onSuccess: onSuccessRead,
                onFailure,
                doNotCheckError,
                aborting,
                cancelToken,
              },
            ),
          );
        })
        .catch((error) => {
          console.log(`${constants[2]}: `, 'error', error);
          onFailure(error);
        });
    });
  };

  const requestGetAffix = (uuid, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'entity/affix/read',
          [
            EntityConstants.GET_AFFIX_REQUEST,
            EntityConstants.GET_AFFIX_SUCCESS,
            EntityConstants.GET_AFFIX_FAILURE,
          ],
          { affix_uuid: uuid },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestUpdateAffix = ({
    data,
    constants,
    partition,
    aborting = false,
    cancelToken,
    signal,
  }) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/affix/update',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            aborting,
            cancelToken,
            signal,
          },
        ),
      );
    })
  );

  const requestUpdateAffixParent = (uuid, newParent, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'entity/affix/update',
          [
            EntityConstants.UPDATE_ENTITY_PARENT_REQUEST,
            EntityConstants.UPDATE_ENTITY_PARENT_SUCCESS,
            EntityConstants.UPDATE_ENTITY_PARENT_FAILURE,
          ],
          {
            affix_uuid: uuid,
            parent: newParent,
          },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  const requestDeleteAffix = (uuid, partition) => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'entity/affix/delete',
          [
            EntityConstants.DELETE_AFFIX_REQUEST,
            EntityConstants.DELETE_AFFIX_SUCCESS,
            EntityConstants.DELETE_AFFIX_FAILURE,
          ],
          { affix_uuid: uuid },
          {
            partition,
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  // PROFILE
  const requestGetProfile = () => (
    new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'utility/actor/me',
          [
            ProfileConstants.GET_PROFILE_INFO_REQUEST,
            ProfileConstants.GET_PROFILE_INFO_SUCCESS,
            ProfileConstants.GET_PROFILE_INFO_FAILURE,
          ],
          {},
          {
            partition: 'USERS',
            onSuccess: resolve,
            onFailure: reject,
            onCancel: reject,
          },
        ),
      );
    })
  );

  // PUBLIC INTERFACES

  // ACTIONS
  const setSelectedEntity = (entity) => dispatch({
    type: EntityConstants.SET_SELECTED_ENTITY,
    payload: entity,
  });

  const resetSelectedEntity = () => dispatch({
    type: EntityConstants.RESET_SELECTED_ENTITY,
  });

  return (
    <ApiContext.Provider
      value={{
        requestUpdateAffix,
        getListOfEntities,
        requestGetProfile,
        requestEntityUpdate,
        requestGetPublicInterfaces,
        requestEntityCreate,
        requestGetPartitions,
        requestCreatePartition,
        requestDeletePartition,
        getListAndReadEntities,
        requestGetEntity,
        requestUpdateEntityParent,
        requestCheckCanDeleteEntity,
        requestDeleteEntity,
        getListAndReadAffix,
        requestGetAffix,
        requestUpdateAffixParent,
        requestDeleteAffix,
        setSelectedEntity,
        resetSelectedEntity,
        requestEntitySet,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export default ApiContextProvider;

ApiContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
