import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { PermissionsContext } from './context';
import paramsURL from '../utils/ParamsURL';
import { allListEntityPerm } from './selectors/selectors';
import { capitalizeFirstChar } from '../utils';
import SaveAndResetBtn from './SaveAndResetBtn';
import ListPermissions from './ListPermissions';

function ExpandRowPermList({
  item,
  removeEntityPermCallback,
  notSavedPermissions,
}) {
  const { t } = useTranslation();
  const {
    entityUUID,
    notSavedDataCallback,
    getActorTreePermissions,
    updateSwitchDataCallback,
    resetFromMainContainer,
    saveTreePermissions,
    defaultPerms,
    viewingAlert,
  } = useContext(PermissionsContext);

  const dataFromUrl = useSelector(paramsURL.get);
  const { expandedActorPerm } = dataFromUrl;

  const allListPerms = useSelector(allListEntityPerm);

  const [treePerms, setTreePerms] = useState([]);
  const [newPerms, setNewPerms] = useState([]);
  const [localReset, setLocalReset] = useState(false);

  const savePermissions = async () => {
    await saveTreePermissions(newPerms);
    notSavedDataCallback('expandCard', false);
    setNewPerms([]);
  };

  const resetData = () => {
    notSavedDataCallback('expandCard', false);
    setLocalReset(true);
    setNewPerms([]);
  };

  const changeSwitch = (currentItem, newPermsData) => {
    const perms = updateSwitchDataCallback(currentItem, [...newPerms], newPermsData);

    setNewPerms(perms);
    notSavedDataCallback('expandCard', !isEmpty(perms));
  };

  const getSelectedActorTreePerms = async () => {
    const res = await getActorTreePermissions(expandedActorPerm);
    // console.log('resresresresresres-------------------222222222', res, 'entityUUID', entityUUID);

    if (isEmpty(res) || !res.some((i) => i.actor_uuid === expandedActorPerm
    && i.entity_uuid === entityUUID)
    ) {
      res.push({
        ...item,
        ...defaultPerms,
        perm_uuid: '',
        entity_type: 'current entity',
        entity_uuid: entityUUID,
        disabled: false,
      });
    }
    const newData = res.map((i) => ({ ...i, customId: `${i.actor_uuid}${i.entity_uuid}` }));
    setTreePerms(newData);
  };

  useEffect(() => {
    if (expandedActorPerm && !isEmpty(allListPerms)) {
      getSelectedActorTreePerms();
    }
  }, [expandedActorPerm, JSON.stringify(allListPerms)]);

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.expandCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  if (get(item, 'is_root')) {
    return viewingAlert(
      'success',
      capitalizeFirstChar(t('permissions.labels.this_is_root')),
      capitalizeFirstChar(t('permissions.labels.Root_has_full_access_to_all_entities')),
    );
  }

  if (get(item, 'is_admin')) {
    return viewingAlert(
      'success',
      capitalizeFirstChar(t('permissions.labels.this_is_administrator')),
      capitalizeFirstChar(t('permissions.labels.administrator_has_full_access_to_all_entities')),
    );
  }

  if (get(item, 'userInBan')) {
    return viewingAlert(
      'error',
      capitalizeFirstChar(t('permissions.labels.this_user_is_in_the_ban_group')),
      capitalizeFirstChar(t('permissions.labels.users_do_not_have_access_to_anything')),
    );
  }

  return (
    <div className="wrapper_expand_row">
      <Row>
        <Col span={8}>
          <SaveAndResetBtn
            className="mb-1"
            disabledButtons={isEmpty(newPerms)}
            saveCallback={savePermissions}
            resetCallback={resetData}
          />
        </Col>

        <Col span={8} className="d-flex justify-content-center align-items-center">
          <h6 className="text-center gray-text">
            {get(item, 'actor_type') === 'group'
              ? capitalizeFirstChar(t('permissions.labels.all_group_permissions'))
              : capitalizeFirstChar(t('permissions.labels.all_user_permissions'))}
          </h6>
        </Col>
      </Row>

      <ListPermissions
        dataTable={treePerms}
        resetSwitchBool={localReset}
        cardName="expandCard"
        customId="customId"
        removePermCallback={removeEntityPermCallback}
        changeSwitchCallback={changeSwitch}
      />
    </div>
  );
}

// ExpandRowPermList.propTypes = {
//   item: PropTypes.object,
//   removeEntityPermCallback: PropTypes.func,
//   notSavedPermissions: PropTypes.object,
// };

export default ExpandRowPermList;
