import PropTypes, { bool, func } from 'prop-types';
import React from 'react';
import { Table } from 'antd';
import _, { get } from 'lodash';
import CustomPagination from './CustomPagination';

function AntTable({
  useCustomPagination,
  bordered = false,
  columns,
  size,
  components,
  currentPage = 1,
  data = [],
  disablePagination,
  expandable,
  headerRowClassName,
  hidePaginationOnSinglePage = false,
  loading,
  onChangePagination,
  onRow,
  onShowSizeChange,
  pageSizeOptions = [25, 50, 100],
  pagination,
  paginationPageSize = 25,
  paginationSize = 'default',
  rowClassName = '',
  rowKey = 'uuid',
  rowSelection,
  showHeader = true,
  total = data ? data.length : 0,
  showSizeChanger = true,
  currentSortCallback,
  hideListSizeOption,
}) {
   const nameSort = {
    ascend: 'asc',
    descend: 'desc',
    default: 'default',
  }

  const defaultPagination = {
    current: +currentPage,
    pageSize: +paginationPageSize,
    hideOnSinglePage: hidePaginationOnSinglePage,
    onChange: onChangePagination,
    onShowSizeChange,
    pageSizeOptions,
    position: ['bottomLeft'],
    // showLessItems: paginationSize === 'small',
    showSizeChanger,
    showTitle: false,
    size: paginationSize,
    total,
    locale: {
      items_per_page: '',
    },
  };

  const onChange = (pagination, filters, sorter) => {
    if (currentSortCallback) {
      // console.log('handleChange', get(sorter, 'columnKey'), nameSort[get(sorter, 'order')]);
      currentSortCallback({
        orderBy: get(sorter, 'columnKey'),
        order: nameSort[get(sorter, 'order')],
      });
    }
  };

  const onHeaderRow = (text, record) => {
    const config = {};

    if (headerRowClassName) {
      config.className = headerRowClassName;
    }
    return config;
  };

  const getRowKey = (item) => {
    const toKey = (path) => _.get(item, path, '').toString().replaceAll(
      ' ',
      '_',
    );

    if (_.isArray(rowKey)) {
      return rowKey.map((rKey) => toKey(rKey)).join('_');
    }
    return toKey(_.toString(rowKey));
  };

  const getRowClassName = (data) => {
    if (!rowClassName) {
      if (rowSelection) {
        return 'table-selectable';
      }
      return '';
    }

    if (_.isFunction(rowClassName)) {
      return rowClassName(data);
    }
    return rowClassName;
  };

  return (
    <>
      <Table
        bordered={bordered}
        columns={columns}
        components={components}
        dataSource={data}
        expandable={expandable}
        loading={loading}
        onRow={onRow}
        onShowSizeChange={onShowSizeChange}
        pagination={(disablePagination || useCustomPagination)
          ? false
          : (pagination || defaultPagination
          )}
        rowSelection={rowSelection}
        showHeader={showHeader}
        showSorterTooltip={false}
        size={size}
        onHeaderRow={onHeaderRow}
        rowClassName={getRowClassName}
        rowKey={getRowKey}
        onChange={onChange}
      />
      {!disablePagination && useCustomPagination
        && (
          <CustomPagination
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={pageSizeOptions}
            onChangePage={onChangePagination}
            currentPage={currentPage}
            total={total}
            pageLimit={paginationPageSize}
            hideListSizeOption={hideListSizeOption}
          />
        )}
    </>
  );
}

export default AntTable;

AntTable.propTypes = {
  bordered: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array.isRequired,
  disablePagination: PropTypes.bool,
  expandable: PropTypes.object,
  headerRowClassName: PropTypes.string,
  hidePaginationOnSinglePage: PropTypes.bool,
  loading: PropTypes.bool,
  onChangePagination: PropTypes.any,
  onRow: PropTypes.func,
  onShowSizeChange: PropTypes.any,
  pageSizeOptions: PropTypes.array,
  pagination: PropTypes.object,
  paginationPageSize: PropTypes.number,
  paginationSize: PropTypes.string,
  rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowKey: PropTypes.string,
  rowSelection: PropTypes.object,
  showHeader: PropTypes.bool,
  size: PropTypes.string,
  total: PropTypes.any,
  currentSortCallback: func,
  hideListSizeOption: bool,
};
