import PropTypes, { bool } from 'prop-types';
import React, { memo, useMemo } from 'react';
import './CustomPagination.css';

function CustomPagination({
  total,
  pageLimit,
  currentPage,
  onChangePage,
  pageSizeOptions,
  onShowSizeChange,
  hideListSizeOption,
}) {
  const totalPages = useMemo(() => Math.ceil(total / pageLimit), [total, pageLimit]);
  const data = useMemo(() => {
    const arr = [];
    for (let i = 1; i <= totalPages; i += 1) {
      arr.push(i);
    }
    return arr;
  }, [totalPages]);
  const onChangePagination = (e) => {
    onChangePage(e);
  };

  if (!data.length) {
    return null;
  }

  const dotsPlus = (
    <li
      aria-hidden
      className="dots ul-pagination-table__li"
      onClick={() => {
        if (+currentPage + 5 > data.length) {
          onChangePagination(data.length);
        } else {
          onChangePagination(+currentPage + 5);
        }
      }}
    >
      ...
    </li>
  );

  const dotsMinus = (
    <li
      aria-hidden
      className="dots ul-pagination-table__li"
      onClick={() => {
        if (currentPage - 5 < 1) {
          onChangePagination(1);
        } else {
          onChangePagination(+currentPage - 5);
        }
      }}
    >
      ...
    </li>
  );

  const listOfPages = (e, key) => (
    <li
      key={key}
      aria-hidden
      className={`ul-pagination-table__li ${+currentPage === e ? 'pagination-item-active' : 'pagination-item'}`}
      onClick={() => onChangePagination(e)}
    >
      {e}
    </li>
  );

  const firstPageNumber = (
    <li
      aria-hidden
      className={`ul-pagination-table__li ${+currentPage === 1 ? 'pagination-item-active' : 'pagination-item'}`}
      onClick={() => onChangePagination(1)}
    >
      1
    </li>
  );

  const lastPageNumber = (
    <li
      aria-hidden
      className={`ul-pagination-table__li ${+currentPage === data.length ? 'pagination-item-active' : 'pagination-item'}`}
      onClick={() => onChangePagination(data.length)}
    >
      {data.length}
    </li>
  );

  return (
    <div className="pagination">
      <ul className="pagination-list ul-pagination-table">
        <li className="ul-pagination-table__li">
          <button
            type="button"
            disabled={+currentPage === 1}
            className={+currentPage === 1 ? 'svg-arrow-disabled' : 'svg-arrow'}
            onClick={() => onChangePage(currentPage - 1)}
          >
            <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="0.9em" height="0.9em" fill="currentColor" aria-hidden="true">
              <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z" />
            </svg>
          </button>
        </li>
        {data.length > 6
          ? currentPage < 5
            ? +currentPage === 4
              ? (
                <>
                  {data.slice(0, 6).map((e, key) => listOfPages(e, key))}
                  {dotsPlus}
                  {lastPageNumber}
                </>
              )
              : (
                <>
                  {data.slice(0, 5).map((e, key) => listOfPages(e, key))}
                  {dotsPlus}
                  {lastPageNumber}
                </>
              )
            : data.length - currentPage <= 3
              ? (
                <>
                  {firstPageNumber}
                  {dotsMinus}
                  {data.slice(currentPage - 3, data.length).map((e, key) => listOfPages(e, key))}
                </>
              )
              : (
                <>
                  {firstPageNumber}
                  {dotsMinus}
                  {data.slice(currentPage - 3, +currentPage + 2).map((e, key) => listOfPages(e, key))}
                  {dotsPlus}
                  {lastPageNumber}
                </>
              )
          : data.map((e, key) => listOfPages(e, key))}
        <li className="ul-pagination-table__li">
          <button
            type="button"
            disabled={+currentPage === data.length}
            className={+currentPage === data.length ? 'svg-arrow-disabled' : 'svg-arrow'}
            onClick={() => onChangePage(+currentPage + 1)}
          >
            <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="0.9em" height="0.9em" fill="currentColor" aria-hidden="true">
              <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z" />
            </svg>
          </button>
        </li>
      </ul>
      {(pageSizeOptions?.length > 0 && !hideListSizeOption)
        && (
          <ul className="pageLimit ul-pagination-table">
            {pageSizeOptions?.map((e, key) => (
              <li
                key={key + e}
                aria-hidden
                className={`ul-pagination-table__li ${pageLimit == e ? 'pageLimit-item-active' : 'pageLimit-item'}`}
                onClick={() => onShowSizeChange(pageLimit, e)}
              >
                {e}
              </li>
            ))}
          </ul>
        )}
    </div>
  );
}

CustomPagination.propTypes = {
  onChangePage: PropTypes.func,
  pageLimit: PropTypes.any,
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onShowSizeChange: PropTypes.any,
  pageSizeOptions: PropTypes.array,
  total: PropTypes.any,
  hideListSizeOption: bool,
};

export default memo(CustomPagination);
