import axiosRequest from '../../api/api';

export const entityUniqClean = ({
  entityUUID,
  key,
}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequest.post(
    'utility/entity/unique/clean',
    [
      'ENTITY_UNIQUE_CLEAN_REQUEST',
      'ENTITY_UNIQUE_CLEAN_SUCCESS',
      'ENTITY_UNIQUE_CLEAN_FAILURE',
    ],
    {
      entity_uuid: entityUUID,
      key,
    },
    {
      onSuccess: resolve,
      onFailure: reject,
    },
  ));
});

export const entityLtreeCheck = (partition) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequest.post(
    'utility/entity/ltree/check',
    [
      'ENTITY_LTREE_CHECK_REQUEST',
      'ENTITY_LTREE_CHECK_SUCCESS',
      'ENTITY_LTREE_CHECK_FAILURE',
    ],
    {},
    {
      onSuccess: resolve,
      onFailure: reject,
      partition,
    },
  ));
});
