import PropTypes from 'prop-types';
import React from 'react';
import {
  capitalizeFirstChar,
  getUserColor,
} from '../utils';

const UserAvatar = ({
  userFirstName,
  userLastName,
  userUUID,
  size = 40,
  className = 'avatar-user',
}) => {
  const initials = `${capitalizeFirstChar(userFirstName[0])}${capitalizeFirstChar(
    userLastName[0],
  )}`;

  return (
    <div
      className={className}
      style={{
        width: size,
        height: size,
        backgroundColor: getUserColor(userUUID),
      }}
    >
      <p>{initials}</p>
    </div>
  );
};

export default UserAvatar;

UserAvatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  userUUID: PropTypes.string.isRequired,
};
