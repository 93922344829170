import _, { head } from 'lodash';
import EntityConstants from '../constants';

const initialState = {
  fetching: false,
  selected: {
    data: null,
    loaded: false,
    can_delete: false,
  },
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case EntityConstants.GET_ENTITY_REQUEST:
    case EntityConstants.GET_AFFIX_REQUEST:
      return {
        ...state,
        fetching: true,
        selected: {
          ...state.selected,
          loaded: false,
          can_delete: false,
        },
      };

    case EntityConstants.GET_ENTITY_SUCCESS:
    case EntityConstants.UPDATE_SUCCESS:
    case EntityConstants.GET_AFFIX_SUCCESS:
      return {
        ...state,
        fetching: false,
        selected: {
          ...state.selected,
          data: _.head(payload),
          loaded: true,
        },
      };

    case EntityConstants.GET_ENTITY_FAILURE:
    case EntityConstants.GET_AFFIX_FAILURE:
      return {
        ...state,
        fetching: false,
        selected: {
          ...state.selected,
          loaded: false,
        },
      };

    case EntityConstants.SET_SELECTED_ENTITY:
      return {
        ...state,
        fetching: false,
        selected: {
          ...state.selected,
          data: payload,
          loaded: false,
        },
      };

    case EntityConstants.RESET_SELECTED_ENTITY:
      return {
        ...state,
        fetching: false,
        selected: {
          ...initialState.selected,
        },
      };

    case EntityConstants.CHECK_CAN_DELETE_ENTITY_REQUEST:
    case EntityConstants.CHECK_CAN_DELETE_ENTITY_FAILURE:
      return {
        ...state,
        fetching: false,
        selected: {
          ...state.selected,
          can_delete: false,
        },
      };

    case EntityConstants.CHECK_CAN_DELETE_ENTITY_SUCCESS:
      return {
        ...state,
        fetching: false,
        selected: {
          ...state.selected,
          can_delete: _.get(head(payload), 'delete'),
        },
      };

    default:
      return state;
  }
};
