import PropTypes from 'prop-types';
import React from 'react';
import {
  Col,
  Row,
} from 'antd';

const PageWrapper = ({
  title,
  children,
}) => (
  <div className="dt-content-wrapper">
    <div className="dt-content">
      <Row>
        <Col span={24} className="dt-content__body">
          <h1 className="header-page">
            {title}
          </h1>
        </Col>
      </Row>
      {children}
    </div>
  </div>
);

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};
