import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstChar } from '../utils';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="dt-footer text">
      <span>
        {capitalizeFirstChar(t(
          'app.headers.copyright',
          'copyright',
        ))}
        {' '}
        54Origins
      </span>
      <span className="px-1">&#169;</span>
      <span>{new Date().getFullYear()}</span>
      <span className="ml-2">version: 6.16.11.9</span>
    </footer>
  );
}

export default Footer;
