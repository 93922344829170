
import { get } from 'lodash';
import EntityPermissionsConstants from '../constants/constants';


const initialState = {
  fetching: true,
  data: [],
  dataInMapByUuid: new Map([]),
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityPermissionsConstants.GET_ENTITY_PERMS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case EntityPermissionsConstants.GET_ENTITY_PERMS_SUCCESS:
      const byUuid = new Map([]);
      get(action, 'payload', []).forEach((item) => {
        byUuid.set(get(item, 'actor_uuid'), item);
      });
      return {
        ...state,
        data: action.payload,
        dataInMapByUuid: byUuid,
        fetching: false,
        error: false,
      };
    case EntityPermissionsConstants.GET_ENTITY_PERMS_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        dataInMapByUuid: new Map([]),
        error: true,
      };

    case EntityPermissionsConstants.CLEAR_ENTITY_PERMS:
      return {
        fetching: true,
        data: [],
        dataInMapByUuid: new Map([]),
        error: false,
      };

    default: return state;
  }
};
