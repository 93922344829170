// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.05.22 || YYYY/MM/DD

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Typography,
  Descriptions,
  Row,
  Skeleton,
  Spin,
  Tag,
} from 'antd';
import { capitalize } from 'lodash';
import {
  mdiAccountDetailsOutline,
  mdiAccountGroupOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';
import axios from 'axios';
import { getNameForActor } from '../utils54origins';
import {replaceDomainByService} from "../../api/appConfig";
import {getCookie} from "../cookies54origins";

const serviceURL = replaceDomainByService('entity');

const { Title } = Typography;

function InfoBlock({
  children,
  title = '',
  iconPath,
  className = '',
}) {
  return (
    <Row gutter={[8, 8]} className={className}>
      <Col>
        <Icon
          path={iconPath}
          size={1}
        />
      </Col>
      <Col flex="auto">
        <p>
          {title}
          :
        </p>
        <div>
          {children}
        </div>
      </Col>
    </Row>
  );
}

const translateForProfilePage = {
  userInfo: ['user info', 'информация о пользователе'],
  missing: ['missing', 'отсутствует'],
  userDetail: ['user detail', 'сведения о пользователе'],
  firstName: ['first name', 'имя'],
  lastName: ['last name', 'фамилия'],
  email: ['user detail', 'эл-почта'],
  birthday: ['birthday', 'день рождения'],
  created: ['account created', 'аккаунт создан'],
  userGroups: ['user groups', 'группы юзера'],
};

function ProfilePage54origins() {
  const { i18n } = useTranslation();

  const [actorInfo, setActorInfo] = useState({});

  const {
    uuid,
    group_names: userGroups,
    created,
    uinfo: {
      first_name,
      last_name,
      birthday,
      email,
    } = {},
  } = actorInfo || {};

  const checkLangAndGetTranslate = (key, isCapitalize = true) => {
    const translate = translateForProfilePage[key][i18n.language === 'en' ? 0 : 1];
    return isCapitalize ? capitalize(translate) : translate;
  };

  const getDescriptionText = (text) => (
    text ? (
      <span style={{
        color: '#1890ff',
      }}
      >
        {text}
      </span>
    ) : checkLangAndGetTranslate('missing')
  );

  const getActorInfo = () => {
    axios({
      method: 'post',
      url: `${serviceURL}/utility/actor/me`,
      headers: {
        'Session-Token': getCookie('Entity'),
      },
    })
      .then(({ data }) => {
        setActorInfo(data);
      });
  };

  const descriptionItem = (descriptionText, keyForTranslate) => (
    <Descriptions.Item
      label={checkLangAndGetTranslate(keyForTranslate)}
      labelStyle={{ width: '33%' }}
    >
      {getDescriptionText(descriptionText)}
    </Descriptions.Item>
  );

  useEffect(() => {
    getActorInfo();
  }, []);

  return (
    <div className="dt-content-wrapper">
      <div
        className="dt-content"
      >
        <div
          style={{
            width: '100%',
            padding: '25px',
          }}
        >
          <Row>
            <Col
              span={24}
            >
              <h4
                style={{
                  color: '#595959',
                }}
              >
                Profile
              </h4>
            </Col>
          </Row>
          <Row gutter={[24, 16]} className="mt-3">
            <Col span={14}>
              <Spin
                spinning={false}
              >
                <Card>
                  <Row gutter={[16, 0]}>
                    <Col>
                      <h5>
                        {checkLangAndGetTranslate('userInfo')}
                      </h5>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row>
                    <Col span={24}>
                      <Skeleton
                        loading={false}
                        paragraph={false}
                        title={{
                          width: '50%',
                          style: {
                            margin: 0,
                            height: 24,
                          },
                        }}
                        active
                        round
                      >
                        <Title level={5}>
                          {capitalize(getNameForActor(actorInfo))}
                        </Title>
                      </Skeleton>
                    </Col>
                    <Col span={24} className="mt-2">
                      <div className="d-flex">
                        <span className="mr-2">
                          UUID:
                          {' '}
                        </span>
                        <Title
                          copyable
                          level={5}
                          type="secondary"
                        >
                          {uuid}
                        </Title>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <InfoBlock
                    iconPath={mdiAccountDetailsOutline}
                    title={checkLangAndGetTranslate('userDetail')}
                  >
                    <Row>
                      <Col span={24}>
                        <Descriptions
                          bordered
                          column={1}
                        >
                          {descriptionItem(first_name, 'firstName')}
                          {descriptionItem(last_name, 'lastName')}
                          {descriptionItem(email, 'email')}
                          {descriptionItem(moment(birthday).format('DD-MM-YYYY'), 'birthday')}
                          {descriptionItem(moment(created).format('DD-MM-YYYY | HH:mm'), 'created')}
                        </Descriptions>
                      </Col>
                    </Row>
                  </InfoBlock>
                  {userGroups && (
                    <InfoBlock
                      title={checkLangAndGetTranslate('userGroups')}
                      iconPath={mdiAccountGroupOutline}
                      className="mt-2"
                    >
                      <Row gutter={[8, 8]}>
                        {userGroups?.map((groupName) => (
                          <Col>
                            <Tag
                              color={groupName.toLowerCase() === 'admin' ? 'red' : 'blue'}
                            >
                              {groupName}
                            </Tag>
                          </Col>
                        ))}
                      </Row>
                    </InfoBlock>
                  )}
                </Card>
              </Spin>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ProfilePage54origins;

InfoBlock.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  iconPath: PropTypes.any,
  title: PropTypes.string,
};
