import qs from 'query-string';
import _ from 'lodash';
import { history } from '../history';

class ParamsURL {
  constructor() {
    this.mainPage = 'admin/profile';
  }

  // is selector
  get(state) {
    return qs.parse(_.get(state, 'router.location.search', ''));
  }

  set(data, path) {
    history.push({
      path: path || _.get(history, 'location.path', this.mainPage),
      search: data
        ? qs.stringify({ ...qs.parse(history.location.search), ...data })
        : '',
    });

    return qs.parse(history.location.search);
  }

  clearKey(keys) {
    history.push({
      pathname: _.get(history, 'location.pathname', ''),
      search: keys
        ? qs.exclude(history.location.search, keys)
        : '',
    });
  }

  clear() {
    history.push({
      path: _.get(history, 'location.path', this.mainPage),
      search: '',
    });
  }
}

const paramsURL = new ParamsURL();

export default paramsURL;
