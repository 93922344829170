import { get, isNil } from 'lodash';
import axiosRequest from '../../api/api';
import EntityPermissionsConstants from '../constants/constants';

export function getActorPermissionsTree({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post(
        'utility/eperm/dissolve',
        ['GET_TREE_PERM'],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export function createEntityPermissions({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post(
        'eperm/create',
        [
          EntityPermissionsConstants.CREATE_ENTITY_PERM_REQUEST,
          EntityPermissionsConstants.CREATE_ENTITY_PERM_SUCCESS,
          EntityPermissionsConstants.CREATE_ENTITY_PERM_FAILURE,
        ],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}

export const getEntityPermission = (config, callback) => (dispatch) => dispatch(
  axiosRequest.post(
    'eperm/get',
    get(config, 'constants') || [
      EntityPermissionsConstants.GET_ENTITY_PERMS_REQUEST,
      EntityPermissionsConstants.GET_ENTITY_PERMS_SUCCESS,
      EntityPermissionsConstants.GET_ENTITY_PERMS_FAILURE,
    ],
    get(config, 'params'),
    {
      partition: get(config, 'partition') || 'PM',
      onSuccess: (response) => {
        if (callback) {
          callback(response);
        }
      },
      onFailure: '',
    },
  ),
);

export const getMyInfo = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(axiosRequest.post(
    'utility/actor/me',
    [
      EntityPermissionsConstants.FETCH_USER_INFO_REQUEST,
      EntityPermissionsConstants.FETCH_USER_INFO_SUCCESS,
      EntityPermissionsConstants.FETCH_USER_INFO_FAILURE,
    ],
    {},
    {
      partition: 'PM',
      onSuccess: resolve,
      onFailure: reject,
    },
  ));
});

export function getListOfAllActors(
  param = {},
  banedUsersFlag,
) {
  const data = {
    not_banned: !isNil(banedUsersFlag) ? banedUsersFlag : true,
    ...param,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        EntityPermissionsConstants.FETCH_ACTORS_REQUEST,
        '',
        EntityPermissionsConstants.FETCH_ACTORS_FAILURE,
      ],
      data,
      {
        onFailure: reject,
        onSuccess: (res) => {
          dispatch({
            type: EntityPermissionsConstants[!isNil(banedUsersFlag)
              ? 'FETCH_ACTORS_AND_BANED_USERS_SUCCESS'
              : 'FETCH_ACTORS_SUCCESS'],
            payload: get(res, 'data', res),
          });
          resolve(res);
        },
      },
    ));
  });
}

export const getGroupsForUser = (uuid) => {
  const params = {
    uuids: uuid,
  };

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequest.post(
      'utility/actor/get',
      [
        EntityPermissionsConstants.GET_ACTORS_NAME_REQUEST,
        {
          type: EntityPermissionsConstants.GET_ACTORS_NAME_SUCCESS,
          payload: (response) => {
            // console.log('response', response);
            resolve(response);
            return response.actors;
          },
        },
        EntityPermissionsConstants.GET_ACTORS_NAME_FAILURE,
      ],
      params,
      {
        // partition: 'PUBLIC',
        onFailure: reject,
      },
    ));
  });
};

export function getUsersByUUID({
  uuid,
  userType = '',
  isGroups,
}) {
  const checkType = () => {
    switch (userType) {
      case 'default':
        return EntityPermissionsConstants.FETCH_ACTORS_SUCCESS;
      case 'project':
        return EntityPermissionsConstants.FETCH_PROJECT_ACTORS_SUCCESS;
      case 'public':
        return EntityPermissionsConstants.FETCH_PUBLIC_ACTORS_SUCCESS;
      case 'temporaryActors':
        return EntityPermissionsConstants.FETCH_TEMPORARY_ACTORS_SUCCESS;
      case 'other':
        return '';
      default:
        return EntityPermissionsConstants.FETCH_ACTORS_SUCCESS;
    }
  };

  const data = {};

  // if need get users from groups, use flag isGroups
  if (isGroups) {
    data.group_uuids = uuid;
  } else {
    data.uuids = typeof uuid === 'string' ? [uuid] : uuid;
  }

  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(axiosRequest.post(
      '/utility/actor/get',
      [
        EntityPermissionsConstants.FETCH_ACTORS_REQUEST,
        {
          type: checkType(),
          payload: (response) => {
            const actors = response?.data || [];

            resolve(actors);

            return actors;
          },
        },
        {
          type: EntityPermissionsConstants.FETCH_ACTORS_FAILURE,
          payload: (error) => {
            reject(error.response ? error.response.data : {});

            return error.response ? error.response.data : {};
          },
        },
      ],
      data,
    ));
  });
}

export const setFetchingForActorList = (bool) => (dispatch) => {
  dispatch({
    type: EntityPermissionsConstants.SET_FETCHING_FOR_ACTORS_LIST,
    payload: bool,
  });
};

export const getMyInfoAndListOfActors = (banedUsersFlag) => (dispatch) => {
  dispatch(getMyInfo())
    .then((res) => {
      const { is_admin = '', is_root = '', uinfo } = res;
      if (is_admin || is_root) {
        dispatch(getListOfAllActors({}, banedUsersFlag));
        return;
      }

      const userGroups = get(uinfo, 'groups', []);

      if (userGroups.length !== 0) {
        // console.log('userGroups', userGroups);
        dispatch(getUsersByUUID({
          uuid: userGroups,
          userType: 'default',
          isGroups: true,
        }));

        dispatch(getGroupsForUser(userGroups));
        return;
      }

      dispatch(setFetchingForActorList(false));
    });
};

export function entitySet({
  data,
  constants,
  partition,
  aborting = false,
  cancelToken,
  signal,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/set',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            aborting,
            cancelToken,
            signal,
          },
        ),
      );
    })
  );
}

export function deleteEntityPerm({
  data,
  partition,
}) {
  return (dispatch) => new Promise((onSuccess, onFailure) => {
    dispatch(
      axiosRequest.post(
        'eperm/delete',
        [
          EntityPermissionsConstants.DELETE_ENTITY_PERMS_REQUEST,
          EntityPermissionsConstants.DELETE_ENTITY_PERMS_SUCCESS,
          EntityPermissionsConstants.DELETE_ENTITY_PERMS_FAILURE,
        ],
        data,
        {
          partition,
          onSuccess,
          onFailure,
        },
      ),
    );
  });
}
