import { Button, Card, Col } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  get, head, isEmpty, omit, uniq,
} from 'lodash';
import { Link } from 'react-router-dom';
import DescriptionPermissions from './DescriptionPermissions';
import SaveAndResetBtn from './SaveAndResetBtn';
import ListPermissions from './ListPermissions';
import { PermissionsContext } from './context';
import { entitySet } from './actions/actionsCreator';
import { getEntityRead } from '../app/entity/actions/entityAction';
import { capitalizeFirstChar } from '../utils';
import {replaceDomainByService} from "../api/appConfig";

const hiddenTableData = ['columnType', 'columnName', 'columnEntityName', 'columnDeletePerm'];

function CreatorPermsCard({ nameSectionPermissions, notSavedPermissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    entityUUID,
    partitionType,
    notSavedDataCallback,
    updateSwitchDataCallback,
    resetFromMainContainer,
    defaultPerms,
  } = useContext(PermissionsContext);

  const [mainEntity, setMainEntity] = useState({});
  const [localReset, setLocalReset] = useState(false);
  const [actorWithNewPermsForSend, setActorWithNewPermForSend] = useState([]);
  const [changedField, setChangedField] = useState([]);

  const changeSwitch = (item, newPermsData, currentNameField) => {
    const newPerms = updateSwitchDataCallback(item, actorWithNewPermsForSend, newPermsData);

    notSavedDataCallback('creatorCard', !isEmpty(newPerms));
    setChangedField((prev) => uniq([...prev, currentNameField]));
    setActorWithNewPermForSend(newPerms);
  };

  const savePermissions = async () => {
    const data = {
      entity_uuid: entityUUID,
      creator_perms: {
        // actor_uuid: propOr('', 'uuid', actorWithNewPermsForSend[0]),
        create: get(actorWithNewPermsForSend[0], 'create', false),
        read: get(actorWithNewPermsForSend[0], 'read', false),
        update: get(actorWithNewPermsForSend[0], 'update', false),
        delete: get(actorWithNewPermsForSend[0], 'delete', false),
        list: get(actorWithNewPermsForSend[0], 'list', false),
        set: get(actorWithNewPermsForSend[0], 'set', false),
      },
    };

    if (changedField.includes('affix_creator_delete')) {
      await dispatch(entitySet({
        data: {
          entity_uuid: entityUUID,
          recursive: get(actorWithNewPermsForSend[0], 'recursive', false),
          creator_perms: {
            affix_creator_delete: get(actorWithNewPermsForSend[0], 'affix_creator_delete', false),
          },
        },
        partition: partitionType,
      }));
    }

    const dataNewEntity = await dispatch(entitySet({
      data,
      partition: partitionType,
    }));

    notSavedDataCallback('creatorCard', false);
    setActorWithNewPermForSend([]);
    setChangedField([]);

    setMainEntity([{
      ...omit(dataNewEntity, ['creator_perms']),
      ...get(dataNewEntity, 'creator_perms'),
    }]);
  };

  const resetData = () => {
    setActorWithNewPermForSend([]);
    setChangedField([]);
    setLocalReset(true);

    notSavedDataCallback('creatorCard', false);
  };

  const getMainEntity = () => {
    const config = {
      data: { entity_uuid: entityUUID },
      partition: partitionType,
    };

    return dispatch(getEntityRead(config));
  };

  const initFunc = async () => {
    const parentEntity = await getMainEntity();
    const { creator_perms } = head(parentEntity);

    setMainEntity([{
      ...omit(head(parentEntity), ['creator_perms']),
      ...creator_perms || defaultPerms,
    }]);
  };

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.creatorCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <Card className="card border-blue">
      <Col className="mb-2 d-flex justify-content-between">
        <h5>{nameSectionPermissions}</h5>
        <a href={replaceDomainByService('auth')} target="_blank" rel="noreferrer">
          <Button
            type="primary"
            ghost
            htmlType="submit"
            size="small"
            className="ml-1 d-flex flex-wrap style_link_auth customAntOutPrimaryBtn text-wrap"
          >
            {capitalizeFirstChar(t('buttons.go_to_auth', 'Need more functionality? Go to Auth service '))}
          </Button>
        </a>
      </Col>

      <DescriptionPermissions />

      <div className="header_table_title justify-content-between mt-2">
        {capitalizeFirstChar(t('labels.entity_creator_permissions'))}
        <SaveAndResetBtn
          className="ml-1"
          disabledButtons={isEmpty(actorWithNewPermsForSend)}
          saveCallback={savePermissions}
          resetCallback={resetData}
        />
      </div>
      <ListPermissions
        dataTable={mainEntity}
        headerClasses="d-none"
        resetSwitchBool={localReset}
        cardName="creatorCard"
        hiddenData={hiddenTableData}
        // removePermCallback={removeCreatorPermCallback}
        changeSwitchCallback={changeSwitch}
      />
    </Card>
  );
}

// CreatorPermsCard.propTypes = {
//   nameSectionPermissions: PropTypes.string,
//   notSavedPermissions: PropTypes.object,
// };

export default CreatorPermsCard;
