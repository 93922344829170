import _ from 'lodash';

export const getEntityDomain = (state) => _.get(
  state,
  'config.entity_domain',
  '',
);

export const getPartitionsByName = (state) => _.get(
  state,
  'config.partitionsByName',
  null,
);

export const getPartitions = (state) => _.get(
  state,
  'config.partitions',
  [],
);
