/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
} from 'react';
import {
  Tag,
  Tree,
} from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getPartitions } from '../../config/selectors';
import { capitalizeFirstChar } from '../../utils';
import { ApiContext } from '../../api/ApiContextProvider';
import EntityConstants from '../constants';

function EntityTreeView({
  getEntityText,
  getAffixText,
  getEntityColor,
  onSelect,
  selectedKeys,
  treeData,
  setTreeData,
}) {
  const {
    getListAndReadEntities,
    getListAndReadAffix,
    resetSelectedEntity,
    requestUpdateEntityParent,
    requestUpdateAffixParent,
  } = useContext(ApiContext);

  const partitions = useSelector(getPartitions);

  const titleRender = ({
    type,
    entity,
  }) => (
    <div
      className="d-flex align-items-center py-1 px-2 w-100"
    >
      <Tag className={`tag-${type === 'affix' ? 'red' : 'gray'}-light mr-2`}>
        {capitalizeFirstChar(type)}
      </Tag>
      <Tag className={`tag-${getEntityColor(entity)} mr-3`}>
        {capitalizeFirstChar(type === 'affix'
          ? _.get(entity, 'affix_type')
          : _.get(entity, 'entity_type'))}
      </Tag>
      <span className="tree-title-text">
        {type === 'affix' ? getAffixText(entity) : getEntityText(entity)}
      </span>
    </div>
  );

  const rootEntityTreeData = partitions.map(({ params, uuid }) => (
    {
      key: uuid,
      partition: _.get(params, 'name'),
      type: 'entity',
      entity: {
        entity_type: 'partition',
        uuid,
        params: {
          name: _.get(params, 'name'),
        },
      },
    }
  ));
  // console.log('rootEntityTreeData', rootEntityTreeData);
  const updateTreeData = (list, key, children) => list.map((node) => {
    // console.log('updateTreeData');
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }

    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }

    return node;
  });

  const getChildrenFromEntities = (
    entities,
    affixes,
    partition,
  ) => [
    ...entities
      .sort((a, b) => _.get(a, 'entity_type', '')
        .localeCompare(_.get(b, 'entity_type', '')))
      .map((item) => (
        {
          key: item.uuid,
          partition,
          type: 'entity',
          entity: item,
        }
      )),
    ...affixes
      .sort((a, b) => _.get(a, 'affix_type', '')
        .localeCompare(_.get(b, 'affix_type', '')))
      .map((item) => (
        {
          key: item.uuid,
          partition,
          type: 'affix',
          entity: item,
          isLeaf: true,
        }
      )),
  ];

  const onLoadData = ({
    key,
    children,
    partition,
  }) => (
    new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
      const config = {
        data: { parent: key },
        partition,
        constants: [
          EntityConstants.GET_ENTITIES_REQUEST,
          EntityConstants.GET_ENTITIES_SUCCESS,
          EntityConstants.GET_ENTITIES_FAILURE,
        ],
      };

      getListAndReadEntities(config)
        .then((data) => {
          const entities = _.get(data, 'data');
          // console.log('entities', entities, 'data', data);
          const configAffix = {
            data: { entity_uuid: key },
            partition,
            constants: [
              EntityConstants.GET_AFFIXES_REQUEST,
              EntityConstants.GET_AFFIXES_SUCCESS,
              EntityConstants.GET_AFFIXES_FAILURE,
            ],
          };
          getListAndReadAffix(configAffix)
            .then((res) => {
              const affixes = _.get(res, 'data');
              // console.log('affixes', affixes, 'res', res);
              setTreeData((prev) => updateTreeData(
                JSON.parse(JSON.stringify(prev)),
                key,
                getChildrenFromEntities(entities, affixes, partition),
              ));
              resolve();
            })
            .catch(resolve);
        })
        .catch(resolve);
    })
  );

  const onDrop = (info) => {
    const {
      node,
      dragNode,
      dropToGap: parentNotChanged,
    } = info;

    const {
      key: uuid,
      type,
      partition,
    } = dragNode;

    const newParent = node.key;
    const dropPos = node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // eslint-disable-next-line consistent-return
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = JSON.parse(JSON.stringify(treeData));

    let dragObj;

    loop(data, uuid, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, newParent, ({ children }) => {
        children = children || [];
        children.unshift(dragObj);
      });
    } else if (
      (
        info.node.props.children || []
      ).length > 0
      && info.node.props.expanded
      && dropPosition === 1
    ) {
      loop(data, newParent, ({ children }) => {
        children = children || [];
        children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;

      loop(data, newParent, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    switch (type) {
      case 'entity':
        if (!parentNotChanged && node.type !== 'affix') {
          requestUpdateEntityParent(uuid, newParent, partition).then(() => {
            setTreeData(data);
          });
        }
        break;
      case 'affix':
        if (!parentNotChanged && node.type !== 'affix') {
          requestUpdateAffixParent(uuid, newParent, partition).then(() => {
            setTreeData(data);
          });
        }
        break;
      default:
        break;
    }
  };

  const initFunc = () => {
    setTreeData(rootEntityTreeData);
  };

  useEffect(() => {
    initFunc();
    return resetSelectedEntity;
  }, [partitions]);

  return (
    <Tree
      treeData={treeData}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      draggable
      titleRender={titleRender}
      loadData={onLoadData}
      onDrop={onDrop}
    />
  );
}

export default EntityTreeView;

EntityTreeView.propTypes = {
  getAffixText: PropTypes.func.isRequired,
  getEntityColor: PropTypes.func.isRequired,
  getEntityText: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};
