import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  Button,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstChar } from '../utils';
import { ApiContext } from '../api/ApiContextProvider';
import {getPartitionsByName} from "../config/selectors";
import {useSelector} from "react-redux";

function DeleteButton({
  targetType,
  uuid,
  className,
  icon = null,
  onSuccess,
  onFailure,
  title = '',
  size = 'small',
  disabled,
  partition,
}) {
  const {
    requestDeleteEntity,
    requestDeleteAffix,
    requestDeletePartition,
  } = useContext(ApiContext);

  const { t } = useTranslation();

  const getPartition = useSelector(getPartitionsByName);


  const onDelete = () => {
    const getDeleteFunctionRequest = () => {
      switch (targetType) {
        case 'entity':
          return requestDeleteEntity;
        case 'affix':
          return requestDeleteAffix;
        case 'partition':
          requestDeletePartition(getPartition[partition]?.header_uuid, partition);
          return null;
        default:
          return null;
      }
    };

    const deleteFunctionRequest = getDeleteFunctionRequest();

    if (deleteFunctionRequest) {
      deleteFunctionRequest(uuid, partition)
        .then(() => onSuccess && onSuccess())
        .catch(() => onFailure && onFailure());
    }
  };

  const showDeleteConfirm = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    Modal.confirm({
      title: capitalizeFirstChar(t(
        `ew.buttons.remove_${targetType}`,
        `remove ${targetType}`,
      )),
      content: capitalizeFirstChar(t(
        `ew.messages.delete_sure_${targetType}`,
        `are you sure you want to delete this ${targetType}?`,
      )),
      maskClosable: true,
      centered: true,
      okText: capitalizeFirstChar(t('auth.buttons.remove', 'remove')),
      okType: 'danger',
      okButtonProps: {
        className: 'button-danger',
      },
      cancelText: capitalizeFirstChar(t('auth.buttons.cancel', 'cancel')),
      cancelButtonProps: {
        className: 'button-secondary-outlined',
      },
      onOk() {
        onDelete();
      },
    });
  };

  return (
    <Button
      className={className || 'button-danger-outlined'}
      onClick={showDeleteConfirm}
      size={size}
      disabled={disabled}
    >
      {icon}
      {title}
    </Button>
  );
}

export default DeleteButton;

DeleteButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  partition: PropTypes.string.isRequired,
  size: PropTypes.string,
  targetType: PropTypes.string,
  title: PropTypes.string,
  uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};
