import _ from 'lodash';
import i18n from '../i18n';
import antNotification from './antNotification';

export const capitalizeFirstChar = (string) => (
  string ? `${string[0].toUpperCase()}${string.slice(1)}` : ''
);

export const textToClipboard = (value) => {
  navigator.clipboard.writeText(value)
    .then(() => {
      antNotification.success(`${capitalizeFirstChar(i18n.t(
        'app.notifications.copied',
        'copied to clipboard',
      ))}!`);
    });
};

export const clearObject = (obj) => JSON.parse(JSON.stringify(
  obj,
  (key, value) => _.identity(value),
));

export const getUserColor = (userUUID) => {
  const userColorNum = (
    Number.parseInt(userUUID[userUUID.length - 1], 16) + Number.parseInt(
      userUUID[0],
      16,
    )
  ) % 10;

  switch (userColorNum) {
    case 7:
      return '#722ed1';
    case 8:
      return '#eb2f96';
    case 0:
      return '#f5222d';
    case 1:
      return '#fa541c';
    case 2:
      return '#fa8c16';
    case 3:
      return '#faad14';
    case 4:
      return '#fadb14';
    case 5:
      return '#a0d911';
    case 6:
      return '#52c41a';
    case 9:
      return '#13c2c2';
    default:
      return '#2f54eb';
  }
};
