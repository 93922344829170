import axios from 'axios';
import MD5 from 'crypto-js/md5';
import _ from 'lodash';
import { EntityConstants } from '../constants/Constants';
import { getToken, antonUrl } from '../../api/api';
import axiosRequest from '../../../api/api';

import AuthHelper from '../../../auth/utils';

const urlForLocalhost = process.env.NODE_ENV === 'dev' ? antonUrl : 'http://116.203.202.25';

export function getEntityRead({
  data,
  constants = ['REQUEST', 'SUCCESS', 'FAILURE'],
  partition = 'PM',
  aborting = true,
  cancelToken,
  signal,
  doNotCheckError,
}) {
  return (dispatch) => (
    new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'entity/read',
          constants,
          data,
          {
            partition,
            onSuccess,
            onFailure,
            aborting,
            cancelToken,
            signal,
            doNotCheckError,
          },
        ),
      );
    })
  );
}

export const getMD5HashFromString = (str) => MD5(str).toString();

const dev = 'https://entity.04releasetest.54origins.com';
const prod = 'https://entity.p2.54origins.com';

export const getOrCreateSingle = ({
  data,
  partition = 'PM',
}) => {
  const {
    key, entity_type, parent,
    params: {
      date = '',
    } = {},
  } = data || {};

  if (!key) {
    data.key = getMD5HashFromString(`${entity_type}${parent}${date}`);
    // data.key = getMD5HashFromString(`${entity_type}${parent}`);
    // data.key = getMD5HashFromString(`${parent}projectACTIVITIES_USER`);
  }

  return (dispatch, getState) => new Promise((resolve, reject) => {
    const getPartitionUUID = (name = 'PM') => _.get(
      getState(),
      `config.partitionsByName.${name}.header_uuid`,
      '',
    );

    axios.post(`${dev}/utility/entity/unique/check`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': AuthHelper.getToken(),
        'Partition-Uuid': getPartitionUUID(partition),
      },
    })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
