import keyMirror from 'keymirror';

const EntityPermissionsConstants = keyMirror({
  CREATE_ENTITY_PERM_REQUEST: null,
  CREATE_ENTITY_PERM_SUCCESS: null,
  CREATE_ENTITY_PERM_FAILURE: null,

  GET_ENTITY_PERMS_REQUEST: null,
  GET_ENTITY_PERMS_SUCCESS: null,
  GET_ENTITY_PERMS_FAILURE: null,

  CLEAR_USERS_WITH_BANED_USERS: null,

  CLEAR_ACTORS_FETCHING: null,

  CLEAR_ENTITY_PERMS: null,

  FETCH_USER_INFO_REQUEST: null,
  FETCH_USER_INFO_SUCCESS: null,
  FETCH_USER_INFO_FAILURE: null,

  FETCH_ACTORS_REQUEST: null,
  FETCH_ACTORS_AND_BANED_USERS_SUCCESS: null,
  FETCH_ACTORS_SUCCESS: null,
  FETCH_ACTORS_FAILURE: null,
  FETCH_PROJECT_ACTORS_SUCCESS: null,
  FETCH_PUBLIC_ACTORS_SUCCESS: null,
  FETCH_TEMPORARY_ACTORS_SUCCESS: null,

  GET_ACTORS_NAME_REQUEST: null,
  GET_ACTORS_NAME_SUCCESS: null,
  GET_ACTORS_NAME_FAILURE: null,

  DELETE_ENTITY_PERMS_REQUEST: null,
  DELETE_ENTITY_PERMS_SUCCESS: null,
  DELETE_ENTITY_PERMS_FAILURE: null,
});

export default EntityPermissionsConstants;
