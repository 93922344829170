import { get } from 'lodash';
import { createSelector } from 'reselect';

export const getNewPermFetching = (state) => get(state, 'createPermForPermissions.fetching', []);

export const getActorsPermsForEntity = (state) => get(state, 'entityPerms.data', []);

export const actorsPermsForEntityInMapByUuid = (state) => get(state, 'entityPerms.dataInMapByUuid', []);

export const fetchingGetEntityPerm = (state) => get(state, 'entityPerms.fetching', '');

export const actorsPermsError = (state) => get(state, 'entityPerms.error');

export const getListOfActorsFetchingFromPerm = (state) => get(state, 'actorListForPerm.listOfActorsFetching');

export const getAllActors = (state) => get(state, 'actorListForPerm.message', []);

export const fetchingGroupInActors = (state) => get(state, 'actorListForPerm.fetchingActorsName');

export const actorsByName = (state) => get(state, 'actorListForPerm.ActorsByName', []);

export const getDefaultActorsAndBanedUsers = (state) => get(state, 'actorListForPerm.defaultUsersAndBanedUsers', []);

export const getAdminGroupUUID = (state) => get(getAllActors(state).find((item) => get(item, 'uinfo.group_name') === 'ADMIN'), 'uuid');

export const getBanGroupUUID = (state) => get(getAllActors(state).find((item) => get(item, 'uinfo.group_name') === 'BAN'), 'uuid');

export const allListEntityPerm = createSelector(
  getDefaultActorsAndBanedUsers,
  actorsByName,
  getActorsPermsForEntity,
  actorsPermsForEntityInMapByUuid,
  (defaultActors, actorsPermByName, actorsPermsForEntity, entityPermInMapByUuid) => {
    const templatePerms = {
      create: false,
      delete: false,
      list: false,
      read: false,
      set: false,
      update: false,
    };

    const defaultList = [...actorsPermByName, ...defaultActors];
    const newData = [];

    const checkActorAndSet = (item) => {
      const isRoot = (item.root_perms_signature && item.root_perms_signature !== null)
      || (item.is_root && item.is_root !== null);

      if (!entityPermInMapByUuid.has(get(item, 'uuid'))) {
        const data = {
          actor_type: item.actor_type,
          actor_uuid: item.uuid,
          entity_uuid: '',
          perm_uuid: '',
          uinfo: item.uinfo,
          is_root: isRoot,
          ...templatePerms,
        };
          // console.log('data',data)
        newData.push(data);
        return;
      }
      const actorFromDefaultList = entityPermInMapByUuid.get(get(item, 'uuid'));
      newData.push({ ...actorFromDefaultList, is_root: isRoot });
    };

    for (let i = 0; i < defaultList.length; i++) {
      checkActorAndSet(defaultList[i]);
    }
    // console.log('SELECTOR-RESELECT', newData)
    return newData;
  },
);
