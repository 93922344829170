import React, { useContext } from 'react';
import {
  mdiTrashCanOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import './PermissionsStyle.scss';
// import { PropTypes } from 'prop-types';

import { get, isEmpty } from 'lodash';
import SwitchComponent from './SwitchComponent';
import { PermissionsContext } from './context';
import EntityParamTag from '../components/EntityParamTag';
import AntTableWithPagination from '../components/antDesign/AntTableWithPagination';

function ListPermissions({
  removePermCallback,
  dataTable,
  title,
  changeSwitchCallback,
  selectRow = null,
  rowEvents,
  resetSwitchBool,
  hiddenData,
  cardName,
  expandRow,
  customId,
  // headerClasses,
}) {
  const {
    getName,
  } = useContext(PermissionsContext);

  const classesRow = cardName === 'expandCard' ? 'row-perm-expand-table' : 'row-perm';

  const rowOfSwitches = (col, row) => (
    <SwitchComponent
      item={row}
      changeSwitchCallback={changeSwitchCallback}
      resetSwitchBool={resetSwitchBool}
      cardName={cardName}
    />
  );

  const formatType = (col, row) => {
    const root = get(row, 'is_root');
    const admin = get(row, 'is_admin');
    const group = col === 'group';
    // const user = col === 'classic_user' || col === 'user';
    const type = root ? 'root' : admin ? 'admin' : group ? 'group' : 'user';
    return (
      <EntityParamTag
        value={type}
        type="user"
      />
    );
  };

  const deleteButton = (col, row) => {
    if (
      // prop('perms_inherited', row) === false
      (get(row, 'actor_type') === 'group' && get(row, 'perm_uuid'))
    || (get(row, 'perm_uuid'))
    ) {
      return (
        <div className="d-flex justify-content-center" onClick={(e) => e.stopPropagation()}>
          <Icon
            path={mdiTrashCanOutline}
            size={0.9}
            onClick={() => removePermCallback(row)}
            className="row-perm-delete "
          />
        </div>
      );
    }
  };

  const formatName = (col, row) => (
    <div className="name-container pl-1">
      <div className="name-container__text">{getName(row)}</div>
    </div>
  );

  const formatEntityName = (col, row) => {
    const actor = get(row, 'actor_type') === 'classic_user' ? 'user' : get(row, 'actor_type');
    const entity = get(row, 'entity_type') === 'project' ? 'current entity' : get(row, 'entity_type');
    return (
      <div className="name-container pl-1">
        <span className="name-container__text">{`${actor} perm from ${entity}`}</span>
      </div>
    );
  };

  const columns = [
    {
      dataIndex: 'p',
      title: 'Entity Name',
      render: formatEntityName,
      className: `${classesRow} ${(hiddenData && hiddenData.includes('columnEntityName')) && 'd-none'}`,
    },
    {
      dataIndex: 'a',
      title: 'Actor Name',
      render: formatName,
      className: `${classesRow} ${(hiddenData && hiddenData.includes('columnName')) && 'd-none'}`,
    },
    {
      dataIndex: 'actor_type',
      title: 'Type',
      render: formatType,
      className: `${classesRow} ${(hiddenData && hiddenData.includes('columnType')) && 'd-none'}`,
    },
    {
      dataIndex: 'perm',
      title: 'Permissions',
      className: classesRow,
      render: rowOfSwitches,
    },
    {
      dataIndex: 'actor_uuid',
      text: '',
      render: deleteButton,
      className: `${classesRow} ${(hiddenData && hiddenData.includes('columnDeletePerm')) && 'd-none'}`,
    },
  ];

  return !isEmpty(dataTable)
    && (
    <div className="w-100 mb-3 wrapper_permissions_table">
      {title
      && (
      // <h6 className="text-center mt-4 gray-text">
      //   {title}
      //   <Badge
      //     className="color-blue ml-1"
      //     count={dataTable.length}
      //   />
      // </h6>
      <div className="header_table_title">{title}</div>
      )}
      <AntTableWithPagination
        onRow={rowEvents}
        rowSelection={selectRow}
        rowClassName={() => 'row-table-in-permissions'}
        rowKey={customId || 'actor_uuid'}
        showHeader={false}
        expandable={expandRow}
        columns={columns}
        data={dataTable}
        hidePaginationOnSinglePage
        disablePagination
      />
    </div>
    );
}

// ListPermissions.propTypes = {
//   hiddenData: PropTypes.any,
//   removePermCallback: PropTypes.func,
//   dataTable: PropTypes.any,
//   title: PropTypes.string,
//   changeSwitchCallback: PropTypes.func,
//   selectRow: PropTypes.object,
//   rowEvents: PropTypes.object,
//   resetSwitchBool: PropTypes.bool,
//   cardName: PropTypes.string,
//   expandRow: PropTypes.object,
//   customId: PropTypes.string,
//   // headerClasses: PropTypes.string,
// };

export default ListPermissions;
