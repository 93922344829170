// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2024.03.29 || YYYY/MM/DD

import Cookies from 'universal-cookie';

const cookies = new Cookies();

const setCookie = (name, value, options = {}) => {
  cookies.set(name, value, options);
};

const getCookie = (name) => cookies.get(name);

const removeCookie = (name) => {
  cookies.remove(name);
};

export { setCookie, getCookie, removeCookie };
