import EntityPermissionsConstants from '../constants/constants';

const initialState = {
  fetching: false,
  listOfActorsFetching: true,
  message: [],
  actorsFromGroups: [],
  defaultUsers: [],
  defaultUsersAndBanedUsers: [],
  publicUsers: [],
  publicUsersFromMainIssues: [],
  projectUsers: [],
  error: '',
  ActorsByName: [],
  temporaryActors: [],
  fetchingActorsName: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EntityPermissionsConstants.FETCH_ACTORS_REQUEST:
      return {
        ...state,
        fetching: true,
        listOfActorsFetching: true,
      };
    case EntityPermissionsConstants.FETCH_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload,
        defaultUsers: action.payload,
        listOfActorsFetching: false,
      };
    case EntityPermissionsConstants.FETCH_ACTORS_AND_BANED_USERS_SUCCESS:
      return {
        ...state,
        fetching: false,
        message: action.payload,
        defaultUsersAndBanedUsers: action.payload,
        listOfActorsFetching: false,
      };
    case EntityPermissionsConstants.FETCH_ACTORS_FAILURE:
      return {
        ...state,
        fetching: false,
        listOfActorsFetching: false,
        message: [],
        actorsFromGroups: [],
        defaultUsers: [],
        publicUsers: [],
        projectUsers: [],
        error: action.payload,
      };

    case EntityPermissionsConstants.FETCH_ACTORS_FROM_PAGINATION_REQUEST:
      return {
        ...state,
        fetchingActorsFromPagination: true,
      };
    case EntityPermissionsConstants.FETCH_ACTORS_FROM_PAGINATION_SUCCESS:
      return {
        ...state,
        fetchingActorsFromPagination: false,
        actorsListFromPagination: action.payload,
      };
    case EntityPermissionsConstants.FETCH_ACTORS_FROM_PAGINATION_FAILURE:
      return {
        ...state,
        fetchingActorsFromPagination: false,
        actorsListFromPagination: [],
      };
    case EntityPermissionsConstants.CLEAR_ACTORS_FROM_PAGINATION:
      return {
        ...state,
        fetchingActorsFromPagination: false,
        actorsListFromPagination: [],
      };

    case EntityPermissionsConstants.FETCH_PROJECT_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        projectUsers: action.payload,
        actorsFromGroups: action.payload,
      };

    case EntityPermissionsConstants.FETCH_TEMPORARY_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        temporaryActors: action.payload,

      };

    case EntityPermissionsConstants.CLEAR_TEMPORARY_ACTORS:
      return {
        ...state,
        temporaryActors: [],
      };

    case EntityPermissionsConstants.FETCH_PUBLIC_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        publicUsers: action.payload,
      };

    case EntityPermissionsConstants.FETCH_PUBLIC_MAIN_ISSUES_ACTORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        publicUsersFromMainIssues: action.payload,
      };

    case EntityPermissionsConstants.GET_ACTORS_NAME_REQUEST:
      return {
        ...state,
        fetchingActorsName: true,
      };

    case EntityPermissionsConstants.GET_ACTORS_NAME_SUCCESS:
      return {
        ...state,
        fetchingActorsName: false,
        ActorsByName: action.payload,
      };

    case EntityPermissionsConstants.GET_ACTORS_NAME_FAILURE:
      return {
        ...state,
        fetchingActorsName: false,
      };

    case EntityPermissionsConstants.CLEAR_ACTORS_FETCHING:
      return {
        ...state,
        listOfActorsFetching: true,
        fetchingActorsName: true,
      };

    case EntityPermissionsConstants.CLEAR_USERS_WITH_BANED_USERS:
      return {
        ...state,
        defaultUsersAndBanedUsers: [],
      };

    case EntityPermissionsConstants.SET_FETCHING_FOR_ACTORS_LIST:
      return {
        ...state,
        listOfActorsFetching: action.payload,
        fetchingActorsName: action.payload,
      };

    default: return state;
  }
};
