import React, { useContext } from 'react';

import {
 Col, Row,
} from 'antd';

import PageWrapper from '../../components/PageWrapper';
import EntityServiceCard from './EntityServiceCard';
import MoveReportsFrom2024to2023 from "../../app/migrationsScripts/delivery/MoveReportsFrom2024to2023";

// project.params.ACTIVITIES_USERё

function AdminDashboard() {
  return (
    <PageWrapper
      title="Admin dashboard"
    >
      <Row>
        <Col span={10}>
          <EntityServiceCard />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {/*<MoveReportsFrom2024to2023 />*/}
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default AdminDashboard;
