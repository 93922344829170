export const appLabel = 'EntityViewer54';
const appLabelForDomain = 'entity-viewer';

const apiProdConfig = {
  entity: 'https://entity.p3.54origins.com',
  auth: 'https://auth.p3.54origins.com',
  'entity-viewer': 'https://entity-viewer.p3.54origins.com',
};

const apiConfigNewDev = {
  auth: 'https://auth.09releasetest.54origins.com',
  entity: ' https://entity.09releasetest.54origins.com',
  'entity-viewer': 'https://entity-viewer.09releasetest.54origins.com',
};

const appLocation = window.location;

export const replaceDomainByService = (service) => (appLocation.host.includes(appLabelForDomain)
  ? appLocation.origin.replace(appLabelForDomain, service) : apiConfigNewDev[service]);

export const configUrlEntity = replaceDomainByService('entity');
