import { RSAA } from 'redux-api-middleware';
import Cookies from 'universal-cookie';
import _ from 'lodash/core';

export const actor = 'bb789f26-5376-4dc1-8885-686d4d8dcb15';

// const EndpointUrl = 'http://192.168.1.157:5555';
const { origin } = window.location;

const urlForLocalhost = 'http://192.168.1.157:5555';

const entityServiceUrl = 'http://116.203.202.25';

export const antonUrl = 'http://192.168.1.219:5555';

export const urlAPI = process.env.NODE_ENV === 'dev' ? antonUrl : entityServiceUrl;

export function getToken() {
  const cookies = new Cookies();
  const authToken = cookies.get('Entity');
  return authToken;
}

export function getUserUuid() {
  const cookies = new Cookies();
  const uuid = cookies.get('UUID');
  return uuid;
}

export function logoutUserAndClearAllCookies(callback) {
  try {
    const cookies = new Cookies();
    const allCookies = cookies.getAll();
    _.map(allCookies, (value, key) => {
      cookies.remove(key);
    });
    callback();
    localStorage.clear();
  } catch (e) {
    console.log('Something wrong with cookies', e);
  }
}

// async way to get data
export function asyncExampleThunkChainedActionCreator(entity, params) {
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `${entity.endpoint}${entity.type}/${objectToQuery(params)}`,
        method: 'GET',
        types: entity.types,
        credentials: 'include',
        headers: entity.headers || { 'Content-Type': 'application/json' },
      },
    });

    // if (actionResponse.error) {
    // the last dispatched action has errored, break out of the promise chain.
    // throw new Error('Promise flow received action error', actionResponse);
    // }

    // you can EITHER return the above resolved promise (actionResponse) here...
    return actionResponse;

    // OR resolve another asyncAction here directly and pass the previous received payload value as argument...
    // return await otherAsyncAction(actionResponse.payload.foo);
  };
}

// create a string for GET request which is based on passed params
export const objectToQuery = (params) => {
  if (!params || params === {}) {
    return '';
  }
  const array = [];
  for (const key in params) {
    if (params[key] instanceof Array) {
      if (params[key].length > 0) {
        for (const val of params[key]) {
          array.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
        }
      }
    } else {
      array.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
    }
  }
  return `?${array.join('&')}`;
};

// just pass from container action types and entity data to get response from server

export const initGet = (entity, params) => ({
  [RSAA]: {
    endpoint: `${entity.endpoint}${entity.type}/${objectToQuery(params)}`,
    method: 'GET',
    types: entity.types,
    credentials: 'same-origin',
    headers: entity.headers || { 'Content-Type': 'application/json' },
  },
});

export const initPost = (action, types) => ({
  [RSAA]: {
    endpoint: `${urlAPI}/${action}`,
    method: 'POST',
    types: types.types,
    credentials: 'include',
    headers: types.headers || { 'Content-Type': 'application/json' },
    body: JSON.stringify(types),
  },
});

export const putMethod = (entity, data) => ({
  [RSAA]: {
    endpoint: `${entity.endpoint}${entity.type}/`,
    method: 'PUT',
    types: entity.types,
    credentials: 'include',
    headers: entity.headers || { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  },
});

export const deleteMethod = (entity, uuid) => ({
  [RSAA]: {
    types: entity.types,
    endpoint: `${entity.endpoint}/${entity.type}/${uuid}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  },
});

export const requestMethod = (action, [REQUEST, SUCCESS, FAILURE], data, method = 'POST') => ({
  [RSAA]: {
    endpoint: `${urlAPI}/${action}`,
    method,
    types: [
      REQUEST,
      SUCCESS,
      {
        type: FAILURE,
        meta: (action, state, res) => {
          console.log('res.status', res);
          switch (res.status) {
            case 401:
              logoutUserAndClearAllCookies(() => {
                setTimeout(() => {
                  window.location.href = '/login';
                }, 500);
              });
              // notification('error', 'Unauthorized');
              break;

            case 403:
              // notification('error', 'Forbidden');
              break;

            default: break;
          }
        },
      },
    ],
    credentials: 'omit',
    headers: { 'Content-Type': 'application/json', 'Session-Token': getToken() },
    body: JSON.stringify(data),
  },
});

export const requestMethodFormData = (action, types, data) => ({
  [RSAA]: {
    endpoint: `${urlAPI}/${action}`,
    method: 'POST',
    types,
    // credentials: 'include',
    headers: { 'Session-Token': getToken() },
    body: data,
  },
});
