import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';

import {
  getAccess,
} from '../profile/selectors';

import ErrorBoundary from '../containers/error/ErrorBoundary';
import Header from './Header';
import SidebarComponent from './SidebarComponent';
import FooterComponent from './Footer';
import Login54Origins from '../54origins/components/Login54Origins';
import AuthHelper from '../auth/utils';
import EntityTreePage from '../entityTree/components/EntityTreePage';
import ProfilePage54origins from '../54origins/components/ProfilePage54origins';
import AdminDashboard from '../admin/components/AdminDashboard';

import { ApiContext } from '../api/ApiContextProvider';
import getLocale from '../locale/selectors';
import { getEntityDomain, getPartitionsByName } from '../config/selectors';
import { appLabel, configUrlEntity } from '../api/appConfig';

function App({ cookies }) {
  const access = useSelector(getAccess);
  const locale = useSelector(getLocale);
  const entityDomain = useSelector(getEntityDomain);
  const headerPartitions = useSelector(getPartitionsByName);

  const {
    requestGetProfile,
    requestGetPublicInterfaces,
    requestGetPartitions,
  } = useContext(ApiContext);

  const checkApt = (renderComponent) => (
    <ErrorBoundary>
      {AuthHelper.isAuthorised() ? (
        renderComponent
      ) : (
        <Redirect to="/login" />
      )}
    </ErrorBoundary>
  );

  const routes = [
    {
      path: '/login',
      exact: true,
      render: AuthHelper.isAuthorised() ? <Redirect to="/entity_tree" /> : <Login54Origins url={configUrlEntity} serviceName={appLabel} />,
    },
    {
      path: '/profile',
      render: checkApt(<ProfilePage54origins />),
    },
    {
      path: '/entity_tree',
      render: checkApt(<EntityTreePage />),
    },
    {
      path: '/admindashboard',
      render: checkApt(<AdminDashboard />),
    },
    {
      key: 'redirect_entity_tree',
      render: checkApt(<Redirect to="/entity_tree" />),
    },
  ];

  const pathname = window.location.href;

  if (pathname.includes('?identifier') || pathname.includes('&identifier')) {
    localStorage.setItem('identifier', pathname.split('identifier=')[1]);
  }

  useEffect(() => {
    if (entityDomain) {
      requestGetPartitions();
    }
  }, [entityDomain]);

  useEffect(() => {
    if (headerPartitions) {
      requestGetProfile()
        .then((response) => AuthHelper.authorizedAccess(_.get(
          response,
          'access',
          false,
        )));
    }
  }, [headerPartitions]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const authToken = _.get(event, 'data.authToken');

      if (authToken) {
        cookies.set('Entity', authToken, { path: '/' });
        window.location.reload();
      }
    });

    AuthHelper.removeSession();

    if (AuthHelper.isAuthorised()) {
      requestGetPublicInterfaces();
    }
  }, [access]);

  useEffect(() => {
  }, [locale]);

  return (
    <div className="dt-root">
      {AuthHelper.isAuthorised() ? <Header /> : null}
      <main className={`${AuthHelper.isAuthorised() ? 'dt-main' : 'mt-0'}`}>
        {AuthHelper.isAuthorised() ? <SidebarComponent /> : null}
        <Switch>
          {routes.map((item) => {
            const {
              path,
              exact,
              render,
              key,
            } = item;

            return (
              <Route
                key={key || path}
                exact={exact || false}
                path={path}
                render={() => render}
              />
            );
          })}
        </Switch>
      </main>
      {AuthHelper.isAuthorised() ? <FooterComponent /> : null}
    </div>
  );
}

export default compose(withRouter, withCookies)(App);

App.propTypes = {
  cookies: PropTypes.instanceOf(Object).isRequired,
};
