import _ from 'lodash';

export const getProfileInfo = (state) => _.get(state, 'profile.info');

export const getProfile = (state) => _.get(state, 'profile');

export const isProfileFetching = (state) => _.get(
  state,
  'profile.fetching',
  false,
);

export const getAccess = (state) => _.get(
  state,
  'profile.info.access',
  false,
);

export const getMeIsAdmin = (state) => _.get(state, 'profile.info.is_admin') || _.get(state, 'profile.info.root');

export const getMeIsRoot = (state) => _.get(state, 'profile.isRoot');

