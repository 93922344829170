import { mdiContentSaveOutline, mdiRestart } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AntButton from '../components/antDesign/AntButton';
import { capitalizeFirstChar } from '../utils';

function SaveAndResetBtn({
  className,
  saveCallback,
  disabledButtons,
  resetCallback,
  withoutWrapper,
}) {
  const { t } = useTranslation();

  const buttonsData = (
    <>
      <AntButton
        className="mr-1 mt-1 btnPrimary"
        onClick={saveCallback}
        disabled={disabledButtons}
        size="small"
        type="primary"
      >
        <Icon
          size={0.7}
          className="mr-1"
          path={mdiContentSaveOutline}
        />

        {capitalizeFirstChar(t('buttons.save', 'save'))}
      </AntButton>
      <AntButton
        size="small"
        className={`${!disabledButtons && 'customAntOutWarningBtn'} mr-1 mt-1`}
        disabled={disabledButtons}
        onClick={resetCallback}
      >
        <Icon
          className="mr-1"
          path={mdiRestart}
          size={0.8}
        />
        {capitalizeFirstChar(
          t('buttons.reset', 'reset'),
        )}
      </AntButton>
    </>
  );

  return (
    <>
      {withoutWrapper
        ? buttonsData
        : (
          <div className={`d-flex ${className}`}>
            {buttonsData}
          </div>
        )}
    </>

  );
}

// SaveAndResetBtn.propTypes = {
//   className: PropTypes.string,
//   saveCallback: PropTypes.func,
//   disabledButtons: PropTypes.bool,
//   resetCallback: PropTypes.func,
// };

export default SaveAndResetBtn;
