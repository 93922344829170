import { mdiChevronDown, mdiClose } from '@mdi/js';
import React, { useEffect, useState, useContext } from 'react';
import Icon from '@mdi/react';
import {
  Card, Col,
} from 'antd';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import StickyBox from 'react-sticky-box';
import AntButton from '../components/antDesign/AntButton';
import { capitalizeFirstChar } from '../utils';
import paramsURL from '../utils/ParamsURL';
import { deleteEntityPerm } from './actions/actionsCreator';
import { PermissionsContext } from './context';
import ListPermissions from './ListPermissions';
import { allListEntityPerm, getAdminGroupUUID, getBanGroupUUID } from './selectors/selectors';
import SaveAndResetBtn from './SaveAndResetBtn';
import ExpandRowPermList from './ExpandRowPermList';

const initialDataInInfo = {
  usersSelectedGroup: [],
  groupsInSelectedUser: [],
  usersWithoutGroup: [],
};

const initTreeData = {
  currentActor: [],
  treePerm: [],
};
const hiddenInSumPermInfoTable = ['columnType', 'columnName', 'columnEntityName', 'columnDeletePerm'];

const hiddenDataInfoListPerm = ['columnEntityName', 'columnDeletePerm'];

function InfoAndSetPermissionsCard({ notSavedPermissions }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    entityUUID,
    partitionType,
    getEntityPerms,
    notSavedDataCallback,
    getActorTreePermissions,
    updateSwitchDataCallback,
    resetFromMainContainer,
    saveTreePermissions,
    defaultPerms,
    checkIsUserType,
    getName,
    viewingAlert,
  } = useContext(PermissionsContext);

  const dataFromUrl = useSelector(paramsURL.get);
  const allListPerms = useSelector(allListEntityPerm);
  const adminGroupUUID = useSelector(getAdminGroupUUID);
  const banGroupUUID = useSelector(getBanGroupUUID);
  const [listPermInInfo, setListPermInInfo] = useState(initialDataInInfo);
  const [localReset, setLocalReset] = useState(false);
  const [treeAndCurrentActor, setTreePermsAndCurrentActor] = useState(initTreeData);
  const [newPerms, setNewPerms] = useState([]);

  const { activeActorPerm, typeActor, expandedActorPerm } = dataFromUrl;
  const {
    usersSelectedGroup,
    groupsInSelectedUser,
    usersWithoutGroup,
  } = listPermInInfo;

  const resetData = () => {
    setNewPerms([]);
    setLocalReset(true);
    notSavedDataCallback('infoCard', false);
  };

  const removeEntityPerm = (item) => {
    const data = {
      perm_uuid: get(item, 'perm_uuid'),
    };

    dispatch(
      deleteEntityPerm({
        data,
        partition: partitionType,
      }),
    ).then(() => {
      getEntityPerms();
    });
  };

  const initialFunc = () => {
    const isAdminGroup = activeActorPerm === adminGroupUUID;
    const isBanedGroup = activeActorPerm === banGroupUUID;
    const usersThisGroup = [];
    const withoutGroup = [];
    const groupsInSelectedActor = [];

    if (typeActor === 'group') {
      allListPerms.forEach((item) => {
        const { uinfo } = item;

        // if ADMIN group is selected
        if (isAdminGroup) {
          if (get(uinfo, 'groups', []).some((i) => i === adminGroupUUID)) {
            usersThisGroup.push({ ...item, is_admin: true });
          }
          return;
        }
        // if BAN group is selected
        if (isBanedGroup) {
          if (get(uinfo, 'groups', []).some((i) => i === banGroupUUID)) {
            usersThisGroup.push({ ...item, userInBan: true });
          }
          return;
        }

        // if group is selected
        if (get(uinfo, 'groups', []).some((i) => i === activeActorPerm)) {
          usersThisGroup.push({ ...item, disabled: true });
          return;
        }

        // UsersWithoutGroup
        if (isEmpty(get(uinfo, 'groups'))) {
          withoutGroup.push({ ...item, disabled: true });
        }
      });
    }

    if (checkIsUserType(typeActor)) {
      const currentActor = allListPerms.find((item) => get(item, 'actor_uuid') === activeActorPerm);
      const groupsCurrentUser = get(currentActor, 'uinfo.groups', []);
      allListPerms.forEach((item) => {
        if (groupsCurrentUser.some((i) => i === get(item, 'actor_uuid'))) {
          groupsInSelectedActor.push({ ...item, disabled: true });
        }
      });
    }

    setListPermInInfo({
      usersSelectedGroup: usersThisGroup,
      groupsInSelectedUser: groupsInSelectedActor,
      usersWithoutGroup: withoutGroup,
    });
  };

  const savePermissions = async () => {
    await saveTreePermissions(newPerms);
    notSavedDataCallback('infoCard', false);
    setNewPerms([]);
  };

  // const clickHeaderColumn = (e) => e.stopPropagation();

  const closeInfoCard = () => {
    paramsURL.clearKey(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
    resetData();
  };

  const onExpandRow = (row) => {
    if (row.actor_uuid === expandedActorPerm) {
      paramsURL.clearKey(['expandedActorPerm']);
      return;
    }
    paramsURL.set({ expandedActorPerm: row.actor_uuid });
  };

  const expandRowActor = {
    expandedRowRender: (row) => (
      <ExpandRowPermList
        item={row}
        removeEntityPermCallback={removeEntityPerm}
        notSavedPermissions={notSavedPermissions}
      />
    ),
    showExpandColumn: true,
    expandedRowKeys: [expandedActorPerm],

    expandIcon: ({ expanded, rowKey, record }) => (
      <Icon
        path={mdiChevronDown}
        size={1}
        rotate={expanded ? 180 : 0}
      />
    ),
  };

  const rowEventsTable = (row) => ({
    onClick: () => { onExpandRow(row); },

  });

  const selectRowTable = {
    mode: 'radio',
    // clickToSelect: true,
    hideSelectColumn: true,
    // classes: activeActorPerm && 'row-perm-selected',
    selectedRowKeys: [expandedActorPerm],
  };

  const showListPermissions = (dataTable, title) => (
    <ListPermissions
      rowEvents={rowEventsTable}
      dataTable={dataTable}
      cardName="infoCard"
      hiddenData={hiddenDataInfoListPerm}
      title={title}
      expandRow={expandRowActor}
      selectRow={selectRowTable}
    />
  );

  const getSelectedActorTreePerms = async () => {
    if (activeActorPerm === 'USER_WITHOUT_GROUP') {
      setTreePermsAndCurrentActor(initTreeData);
      return;
    }

    const res = await getActorTreePermissions(activeActorPerm);
    // console.log('resresresresresres-------------------', res);
    const getCurrentActor = allListPerms.find((item) => item.actor_uuid === activeActorPerm);

    if (isEmpty(res) || !res.some((i) => i.actor_uuid === activeActorPerm
    && i.entity_uuid === entityUUID)
    ) {
      res.push({
        ...getCurrentActor,
        ...defaultPerms,
        entity_type: 'current entity',
        entity_uuid: entityUUID,
      });
    }
    const newData = res.map((item) => ({ ...item, customId: `${item.actor_uuid}${item.entity_uuid}` }));
    setTreePermsAndCurrentActor({
      currentActor: [{ ...getCurrentActor, disabled: true }],
      treePerm: newData,
    });
  };

  const changeSwitchTreePerm = (currentItem, newPermsData) => {
    const perms = updateSwitchDataCallback(currentItem, newPerms, newPermsData);
    setNewPerms(perms);
    notSavedDataCallback('infoCard', !isEmpty(perms));
  };

  const buttonCloseCard = (
    <AntButton
      size="small"
      className="customAntOutOrangeBtn mr-1 mb-1"
      onClick={closeInfoCard}
    >
      <Icon
        className="mr-1"
        path={mdiClose}
        size={0.8}
      />
      {capitalizeFirstChar(
        t('wms.buttons.close', 'close'),
      )}
    </AntButton>
  );

  const checkNameGroup = (name) => getName(get(treeAndCurrentActor, 'currentActor[0]')) === name;

  useEffect(() => {
    if (localReset) {
      setLocalReset(false);
    }

    if (resetFromMainContainer && notSavedPermissions.infoCard) {
      resetData();
    }
  }, [localReset, resetFromMainContainer]);

  useEffect(() => {
    if (activeActorPerm) {
      getSelectedActorTreePerms();

      initialFunc();
    }
  }, [JSON.stringify(allListPerms), activeActorPerm]);

  if (get(treeAndCurrentActor, 'currentActor[0].is_root')) {
    return (
      <Card className="card border-volcano ml-4">
        {buttonCloseCard}
        {viewingAlert(
          'success',
          capitalizeFirstChar(t('wms.labels.this_is_root')),
          capitalizeFirstChar(t('wms.labels.Root_has_full_access_to_all_entities')),
        )}
      </Card>
    );
  }

  return (
    <StickyBox offsetTop={20} offsetBottom={20}>
      <Card className="card border-volcano ml-4">
        <Col className="pb-2">
          {buttonCloseCard}

          {checkNameGroup('BAN')
        && viewingAlert(
          'error',
          capitalizeFirstChar(t('permissions.labels.this_is_ban_group')),
          capitalizeFirstChar(t('permissions.labels.users_of_this_group_do_not_have_access')),
        )}

          {checkNameGroup('ADMIN')
        && viewingAlert(
          'success',
          // capitalizeFirstChar(t('wms.noun.success')),
          capitalizeFirstChar(t('permissions.labels.this_is_admin_group')),
          capitalizeFirstChar(t('permissions.labels.users_of_this_group_has_full_access_to_all_entities')),
        )}

          {(activeActorPerm !== 'USER_WITHOUT_GROUP'
        && !checkNameGroup('ADMIN')
        && !checkNameGroup('BAN'))
      && (
      <>
        <div className="header_table_title orange_color mt-1">
          {getName(get(treeAndCurrentActor, 'currentActor[0]'))}
        </div>

        <ListPermissions
          dataTable={get(treeAndCurrentActor, 'currentActor')}
          cardName="creatorCard"
          hiddenData={hiddenInSumPermInfoTable}
          headerClasses="d-none"
        />

        <div className="header_table_title orange_color justify-content-between">
          {typeActor === 'group'
            ? capitalizeFirstChar(t('permissions.labels.all_group_permissions'))
            : capitalizeFirstChar(t('permissions.labels.all_user_permissions'))}
          <SaveAndResetBtn
              // withoutWrapper
            disabledButtons={isEmpty(newPerms)}
            saveCallback={savePermissions}
            resetCallback={resetData}
          />
        </div>

        <ListPermissions
          dataTable={get(treeAndCurrentActor, 'treePerm')}
          cardName="infoCard"
          customId="customId"
          resetSwitchBool={localReset}
          removePermCallback={removeEntityPerm}
          changeSwitchCallback={changeSwitchTreePerm}
        />
      </>
      )}
        </Col>

        {!isEmpty(usersSelectedGroup) && showListPermissions(
          usersSelectedGroup,
          // capitalizeFirstChar(t('wms.permissions.headers.users', 'Users')),
          capitalizeFirstChar(t('permissions.labels.users_in_a_group')),

        )}

        {activeActorPerm === 'USER_WITHOUT_GROUP' && showListPermissions(
          usersWithoutGroup,
          // capitalizeFirstChar(t('wms.permissions.headers.users', 'Users')),
          capitalizeFirstChar(t('permissions.labels.users_in_a_group')),

        )}

        {!isEmpty(groupsInSelectedUser)
       && showListPermissions(
         groupsInSelectedUser,
         //  capitalizeFirstChar(t('wms.permissions.headers.Groups', 'Groups')),
         capitalizeFirstChar(t('permissions.labels.groups_the_user_belongs_to')),
       )}
      </Card>
    </StickyBox>
  );
}

export default InfoAndSetPermissionsCard;
