import axios from 'axios';
import _ from 'lodash';
import i18next from 'i18next';
import { capitalizeFirstChar } from '../utils';
import i18n from '../i18n';
import AuthHelper from '../auth/utils';
import antNotification from '../utils/antNotification';
import { logoutAndClearAllCookies } from '../54origins/utils54origins';
import { replaceDomainByService } from './appConfig';

const apiProdConfig = {
  entity: 'https://entity.p3.54origins.com',
  auth: 'https://auth.p3.54origins.com',
  'entity-viewer': 'https://entity-viewer.p3.54origins.com',
};

const apiConfigNewDev = {
  auth: 'https://auth.09releasetest.54origins.com',
  entity: ' https://entity.09releasetest.54origins.com',
  'entity-viewer': 'https://entity-viewer.09releasetest.54origins.com',
};

const parseError = (error) => {
  const status = _.get(error, 'response.status', 500);
  const message = _.get(error, 'response.data.error_message', '');
  const errorContent = _.get(error, 'response.data.error_content', []);

  return {
    message,
    status,
    errorContent,
  };
};

const handleError = (error) => {
  const {
    status,
    message,
    errorContent,
  } = parseError(error);

  switch (status) {
    case 400:
      if (message) {
        antNotification.error(message);
      }
      if (errorContent.length > 0) {
        const messages = errorContent
          .map(({
            field,
            message: msg,
          }) => capitalizeFirstChar(`${i18next.t(
            `app.headers.${field}`,
            field,
          )}: ${msg}`))
          .join('\t');
        antNotification.error(messages);
      }
      break;
    case 401:
      logoutAndClearAllCookies(() => {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      });
      break;
    case 403:
      antNotification.error(capitalizeFirstChar(i18n.t(
        'app.notifications.forbidden',
        'access rights denied',
      )));
      break;
    case 430:
      logoutAndClearAllCookies(() => {
        setTimeout(() => {
          window.location.href = '/login';
        }, 500);
      });
      break;

    default:
      break;
  }
};

class AxiosAPI {
  constructor() {
    this.allSources = {};
  }

  getSourceToken(requestType) {
    const source = this.allSources[requestType] || axios.CancelToken.source();
    this.allSources[requestType] = source;

    return source.token;
  }

  resetSource(requestType) {
    delete this.allSources[requestType];
  }

  request(endpoint, types = [], data, options = {}, method) {
    const {
      onSuccess,
      onFailure,
      onCancel,
      onFinished,
      aborting,
      url,
      withoutSessionToken,
      service,
      partition,
    } = options;
    const [REQUEST, SUCCESS, FAILURE, CANCELED = null] = types;
    let headers = {
      'Content-Type':
        data instanceof FormData ? 'multipart/form-data' : 'application/json',

      'Accept-Language': localStorage.getItem('locale') || 'en',
    };

    if (!withoutSessionToken) {
      headers = {
        ...headers,
        'Session-Token': AuthHelper.getToken(),
      };
    }

    return (dispatch, getState) => {
      const getPartitionUUID = (name = 'PM') => _.get(
        getState(),
        `config.partitionsByName.${name}.header_uuid`,
        '',
      );
      if (partition) {
        headers = {
          ...headers,
          'Partition-Uuid': getPartitionUUID(partition),
        };
      }

      const getUrl = () => {
        if (!service || service === 'entity') {
          return _.get(getState(), 'config.entity_domain', '') || replaceDomainByService(
            service || 'entity',
          );
        }

        return replaceDomainByService(service);
      };
      //  console.log('endpoint',endpoint)
      let config = {
        url: `${url || getUrl()}/${endpoint}`,
        method,
        data,
        headers,
      };

      if (aborting) {
        config = {
          ...config,
          cancelToken: this.getSourceToken(REQUEST),
        };
      }

      if (REQUEST) {
        dispatch({ type: REQUEST });
      }

      const state = getState();

      axios
        .request(config)
        .then((response) => {
          if (aborting) {
            this.resetSource(REQUEST);
          }

          if (SUCCESS) {
            dispatch({
              type: SUCCESS.type || SUCCESS,
              payload: SUCCESS.payload
                ? SUCCESS.payload(response.data, state, response.status)
                : response.data,
            });
          }

          if (onSuccess) {
            onSuccess(response.data, state, response.status);
          }

          if (onFinished) {
            onFinished(response.data, state, response.status);
          }
        })
        .catch((error) => {
          handleError(error);

          if (onFinished) {
            onFinished(parseError(error));
          }

          if (axios.isCancel) {
            if (onCancel) {
              onCancel(parseError(error));
            }

            if (CANCELED) {
              dispatch({
                type: CANCELED.type || CANCELED,
              });
            }
          } else {
            if (onFailure) {
              onFailure(parseError(error));
            }

            if (FAILURE) {
              dispatch({
                type: FAILURE.type || FAILURE,
                payload: FAILURE.payload
                  ? FAILURE.payload(parseError(error), state)
                  : parseError(error),
              });
            }
          }

          if (aborting) {
            this.resetSource(REQUEST);
          }
        });
    };
  }

  get(endpoint, types, options) {
    return this.request(
      endpoint,
      types,
      null,
      options,
      'get',
    );
  }

  post(endpoint, types, data, options) {
    return this.request(
      endpoint,
      types,
      data,
      options,
      'post',
    );
  }
}

const axiosRequest = new AxiosAPI();

export default axiosRequest;
