import PropTypes from 'prop-types';
import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiAccountEyeOutline, mdiAccountQuestion,
  mdiAccountSearchOutline,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiArchive,
  mdiArchiveSettingsOutline,
  mdiBellOutline,
  mdiBlockHelper,
  mdiBugOutline,
  mdiChatOutline,
  mdiCheck,
  mdiCheckBold,
  mdiCheckboxMarkedCircleOutline,
  mdiClockCheckOutline,
  mdiClockTimeThreeOutline,
  mdiCloseThick,
  mdiEyeOutline,
  mdiHelp,
  mdiLayersOutline,
  mdiLayersRemove,
  mdiLayersSearchOutline,
  mdiMagnify,
  mdiNotebookOutline,
  mdiPause,
  mdiProgressClock,
  mdiShieldSunOutline,
  mdiWrenchOutline,
} from '@mdi/js';

import Icon from '@mdi/react';
import { take } from 'lodash';
import { capitalizeFirstChar } from '../utils';

function EntityParamTag({
  param = '',
  type = 'title',
  value = '',
  maxLength,
  tooltip,
}) {
  const { t } = useTranslation();

  let title = '';

  if (value && typeof (value) !== 'object') {
    let newValue;

    switch (value) {
      case 'projectDocument': newValue = 'document'; break;
      case 'projectIdea': newValue = 'idea'; break;
      case 'projectGoal': newValue = 'goal'; break;
      case 'deployed': newValue = 'deployed_closed'; break;
      default: newValue = value;
    }
    newValue = newValue.toString().toLowerCase();

    title = capitalizeFirstChar(t(`wms.${param.toLowerCase()}.${newValue}`, newValue));
  }
  const colorType = {
    root: 'purple',
    admin: 'volcano',
    user: 'blue',
    group: 'gold',
    classic_user: 'blue',
  };

  const getClassName = () => {
    // console.log('value', value);
    switch (value.toLowerCase()) {
      case 'bug':
        return 'bug-tracker';
      case 'feature':
        return 'feature-tracker';
      case 'ticket':
        return 'ticket-tracker';
      case 'document':
      case 'projectdocument':
      case 'failed':
      case 'projectgoal':
      case 'projectGoal':

        // case 'comment':
        return 'orange';
      case 'production':
      case 'project':
      case 'current':
        return 'red';
      case 'functional':
      case 'general':
      case 'testcase':
      case 'comment':
        return 'blue';
      case 'issue':

        // case 'sprint':
      case 'none':
        return 'lightBlue';
      case 'testcycle':
        return 'skyBlue';
      case 'testsuite':
      case 'project description':
        return 'lightOrange';
      case 'release':
        return 'darkRed';
      case 'version':
        return 'violet';
      case 'vector':
        return 'darkblue';
      case 'next_plus':
        return 'green';
      case 'next':
        return 'lightYellow';
      case 'next_2plus':
      case 'idea':
      case 'projectidea':
      case 'timelog':
        return 'yellow';
      case 'previous':
        return 'lightGrey';
      case 'old':
        return 'none';
      case 'in progress':
      case 'planning':
        return 'progress';
      case 'ready for test':
        return 'test';
      case 'to release':
        return 'release';
      case 'deploy':
        return 'green';
      case 'completed':
        return 'active';
      case 'archived':
        return 'archive';
      case 'protected':
        return 'protected';
      default:
        return value.toLowerCase();
    }
  };

  const getIconPath = () => {
    switch (value.toLowerCase()) {
      case 'protected':
        return mdiShieldSunOutline;
      case 'created':
        return mdiBellOutline;
      case 'acquainted':
        return mdiAccountEyeOutline;
      case 'accepted':
        return mdiAccountCheckOutline;
      case 'rejected':
        return mdiAccountCancelOutline;
      case 'in progress':
        return mdiProgressClock;
      case 'developing':
        return mdiLayersOutline;
      case 'resolved':
      case 'to release':
        return mdiCheckBold;
      case 'active':
        return mdiCheckboxMarkedCircleOutline;
      case 'planning':
        return mdiNotebookOutline;
      case 'ready for test':
      case 'test':
        return mdiMagnify;
      case 'testing':
        return mdiLayersSearchOutline;
      case 'self testing':
        return mdiAccountSearchOutline;
      case 'review':
        return mdiEyeOutline;
      case 'paused':
        return mdiPause;
      case 'production':
        return mdiAlertCircleOutline;
      case 'discussion':
        return mdiChatOutline;
      case 'backlog':
        return mdiClockTimeThreeOutline;
      case 'blocked':
        return mdiBlockHelper;
      case 'close':
      case 'closed':
      case 'failed':
        return mdiCloseThick;
      case 'bugs':
        return mdiBugOutline;
      case 'fixing':
        return mdiWrenchOutline;
      case 'test failed':
        return mdiLayersRemove;
      case 'not reproducible':
        return mdiAlertOutline;
      case 'ready for deploy':
        return mdiClockCheckOutline;
      case 'deployed':
      case 'completed':
        return mdiCheck;
      case 'abandoned':
        return mdiArchiveSettingsOutline;
      case 'discuss':
        return mdiAccountQuestion;
      case 'archived':
        return mdiArchive;
      default:
        return mdiHelp;
    }
  };

  if (!value) {
    return null;
  }

  switch (type) {
    case 'badge':
      return (
        <Tag className={`entityTagBadge ${getClassName()}`}>
          {title}
        </Tag>
      );
    case 'char':
      return (
        <Tooltip
          className={`entityTag ${getClassName()}`}
          placement="top"
          title={tooltip || title}
        >
          <span className="icon default_style_icon_table">
            {title[0].toUpperCase()}
          </span>
        </Tooltip>
      );
    case 'charNoCut':
      return (
        <Tooltip
          className={`entityTag ${getClassName()}`}
          placement="top"
          title={tooltip || title}
        >
          <span className="icon default_style_icon_table_no_cut">
            {capitalizeFirstChar(title)}
          </span>
        </Tooltip>
      );
    case 'icon':
      return (
        <Tooltip
          className={`entityTag ${getClassName()}`}
          placement="top"
          title={tooltip || title}
        >
          <span className="icon default_style_icon_table">
            <Icon path={getIconPath()} size={0.7} />
          </span>
        </Tooltip>

      );
    case 'iconWithStatus':
      return (
        <Tag className={`entityTag ${getClassName()} with-border`}>
          <Icon path={getIconPath()} size={0.6} className="mr-1" />
          <span>{title}</span>
        </Tag>
      );
    case 'iconAndHideWrapper':
      return (

      // <span className={`entityTag_without_background ${getClassName()}`}>
        <Icon path={getIconPath()} size={0.7} className="mr-1" />
      // </span>

      );
    case 'title':
      return (
        <Tooltip
          title={tooltip || value}
          trigger={tooltip || (maxLength && title.length > maxLength)}
        >
          <span className="grayText">
            {maxLength && title.length > maxLength ? `${take(maxLength, title)}...` : title}
          </span>
        </Tooltip>
      );
    case 'user':
      return (<Tag className="ml-1" color={colorType[value]}>{value}</Tag>);
    case 'value':
    default:
      return (
        <Tooltip
          title={tooltip || value}
          placement="right"
          trigger={tooltip || (maxLength && title.length > maxLength) ? 'hover' : ''}
        >
          <span className="grayText wordBreak">
            {maxLength && value.length > maxLength ? `${take(maxLength, value)}...` : value}
          </span>
        </Tooltip>
      );
  }
}

export default EntityParamTag;

EntityParamTag.propTypes = {
  // maxLength: PropTypes.number,
  param: PropTypes.string.isRequired,
  // tooltip: PropTypes.string,
  // type: PropTypes.string,
  value: PropTypes.string.isRequired,
};
