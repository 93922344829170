import { notification } from 'antd';

class AntNotification {
  constructor() {
    this.display = (type, message, description) => notification[type]({
      message: message || type,
      description,
      top: 74,
      duration: description ? description.length * 0.08 : 3,
    });
  }

  error(message, description) {
    this.display('error', message, description);
  }

  success(message, description) {
    this.display('success', message, description);
  }

  warning(message, description) {
    this.display('warning', message, description);
  }

  info(message, description) {
    this.display('info', message, description);
  }
}

const antNotification = new AntNotification();

export default antNotification;
