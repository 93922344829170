import _ from 'lodash';
import ConfigConstants from '../constants';

const initialState = {
  fetching: false,
  entity_domain: '',
  partitionsByName: null,
  partitions: [],
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ConfigConstants.GET_PUBLIC_INTERFACES_REQUEST:
    case ConfigConstants.GET_PARTITIONS_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ConfigConstants.GET_PUBLIC_INTERFACES_SUCCESS: {
      const domain = _.get(
        payload,
        'public_interface[0].params.backend_domain',
        '',
      );

      return {
        ...state,
        fetching: false,
        entity_domain: domain,
      };
    }

    case ConfigConstants.GET_PARTITIONS_SUCCESS: {
      const partitions = payload.reduce((acc, item) => (
        {
          ...acc,
          [_.get(item, 'params.name')]: item,
        }
      ), {});
      // console.log('partitions', partitions);
      return {
        ...state,
        fetching: false,
        partitionsByName: partitions,
        partitions: payload,
      };
    }

    case ConfigConstants.GET_PUBLIC_INTERFACES_FAILURE:
    case ConfigConstants.GET_PARTITIONS_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
};
