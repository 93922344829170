import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import locales from '../locale/reducers';
import profileInfo from '../profile/reducers';
import config from '../config/reducers';
import entities from '../entityTree/reducers';
import GetEntityPermissions from '../permissions/reducers/GetEntityPermissions';
import CreatePermForPermissions from '../permissions/reducers/CreatePermForPermissions';
import ActorsReducerForPerms from '../permissions/reducers/ActorsReducerForPerms';

const createRootReducer = (history) => combineReducers({
  config,
  entities,
  locales,
  entityPerms: GetEntityPermissions,
  createPermForPermissions: CreatePermForPermissions,
  actorListForPerm: ActorsReducerForPerms,
  profile: profileInfo,
  router: connectRouter(history),
});

export default createRootReducer;
