import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import { allListEntityPerm } from './selectors/selectors';
import { PermissionsContext } from './context';
import paramsURL from '../utils/ParamsURL';
import { capitalizeFirstChar } from '../utils';
import AntDesignSearchBox54origins from '../54origins/components/AntDesignSearchBox54origins';

const initialSearchData = {
  dataSearchUsers: [],
  dataSearchGroup: [],
};
function SearchPermissions({
  setDataSearchCallback,
}) {
  const { t } = useTranslation();

  const allListPerms = useSelector(allListEntityPerm);

  const {
    checkIsUserType,
  } = useContext(PermissionsContext);

  //   const [searchValue, setSearchValue] = useState('');

  const formatName = (row) => {
    const nameUser = `${get(row, 'uinfo.first_name', '')}
     ${get(row, 'uinfo.last_name', '')}}`;
    const nameGroup = get(row, 'uinfo.group_name', '');

    return get(row, 'actor_type') !== 'group' ? nameUser : nameGroup;
  };

  let timer;

  const onSearchProject = (value) => {
    clearTimeout(timer);
    if (value) {
      timer = setTimeout(() => {
        paramsURL.clearKey(['activeActorPerm', 'typeActor', 'expandedActorPerm']);

        const searchUsers = [];
        const searchGroup = [];

        allListPerms.forEach((item) => {
          if (formatName(item).match(new RegExp(value, 'i'))) {
            if (item.actor_type === 'group') {
              searchGroup.push({ ...item, disabled: true });
            }
            if (checkIsUserType(item.actor_type)) {
              searchUsers.push({ ...item, disabled: true });
            }
          }
        });

        setDataSearchCallback({
          dataSearchUsers: searchUsers,
          dataSearchGroup: searchGroup,
        });
      }, 200);
    } else {
      paramsURL.clearKey(['activeActorPerm', 'typeActor', 'expandedActorPerm']);
      setDataSearchCallback(initialSearchData);
    }
  };

  return (
    <AntDesignSearchBox54origins
      id="table-search-bar"
      htmlFor="table-search-bar"
      onSearch={onSearchProject}
      placeholder={capitalizeFirstChar(
        t(
          'wms.placeholders.search_type_here',
          'Enter project name or area',
        ),
      )}
    />
  );
}

SearchPermissions.propTypes = {
  // setDataSearchCallback: PropTypes.func,
};

export default SearchPermissions;
