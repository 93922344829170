import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import PageWrapper from '../../components/PageWrapper';
import EntityTreeView from './EntityTreeView';
import EntityInfoAndPermissions from './EntityInfoAndPermissions';

import { capitalizeFirstChar } from '../../utils';
import { getSelectedEntity } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';


function EntityTreePage() {
  const {
    requestGetEntity,
    requestGetAffix,
    resetSelectedEntity,
    setSelectedEntity,
  } = useContext(ApiContext);

  const { t } = useTranslation();

  const selectedEntity = useSelector(getSelectedEntity);

  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedPartition, setSelectedPartition] = useState('');

  const onSelectItem = (keys, info) => {
    const {
      node,
      selected,
    } = info;
    const {
      type,
      key: uuid,
      partition,
      entity,
    } = node;

    setSelectedPartition(partition);
    setSelectedKeys(keys);

    if (selected) {
      switch (type) {
        case 'entity':
          requestGetEntity(uuid, partition)
            .catch(() => setSelectedEntity(entity));
          break;
        case 'affix':
          requestGetAffix(uuid, partition)
            .catch(() => setSelectedEntity(entity));
          break;
        default:
          break;
      }
    } else {
      resetSelectedEntity();
    }
  };

  const getEntityText = (entity) => {
    const entityType = _.get(entity, 'entity_type');

    switch (entityType) {
      case 'project':
      case 'vector':
      case 'issue':
      case 'projectDocument':
        return _.get(entity, 'params.title', entityType);
      case 'area':
        return _.get(entity, 'params.name', entityType);
      case 'user': {
        const {
          first_name,
          last_name,
          service_name,
        } = entity.uinfo || {};
        const fullName = first_name ? `${first_name} ${last_name}` : service_name;

        return fullName || capitalizeFirstChar(t(
          'ew.headers.no_name',
          'no name',
        ));
      }
      case 'CookBookTemplate':
        return _.get(entity, 'params.label', entityType);
      case 'sprint':
        return _.get(entity, 'params.title', entityType);
      case 'partition':
        return _.get(entity, 'params.name', entityType);
      default:
        return entityType;
    }
  };

  const getAffixText = (affix) => {
    const affixType = _.get(affix, 'affix_type', '');

    switch (affixType) {
      case 'comment': {
        const comment = _.get(affix, 'params.comment', '');

        if (_.isObject(comment)) {
          return `${comment.action} ${comment.param}...`;
        }
        return `${comment.slice(0, 40)}...`;
      }
      case 'projectDocument':
        return _.get(affix, 'params.title', affixType);
      default:
        return affixType;
    }
  };

  const getEntityColor = (entity) => {
    const entityType = _.get(entity, 'entity_type');

    switch (entityType) {
      case 'project':
        return 'volcano';
      case 'vector':
        return 'orange';
      case 'sprint':
        return 'yellow';
      case 'issue':
        return 'blue';
      case 'user':
        return 'geekblue';
      case 'projectDocument':
        return 'green';
      case 'area':
        return 'cyan';
      case 'CookBookTemplate':
        return 'purple';
      case 'partition':
        return 'dark';
      default:
        return 'snow';
    }
  };

  const clearSelectedEntity = (uuid) => {
    const resetEntity = () => {
      setSelectedKeys([]);
      resetSelectedEntity();
    };

    if (uuid) {
      if (selectedKeys[0] === uuid) {
        resetEntity();
      }
    } else {
      resetEntity();
    }
  };

  const deleteTreeNode = (uuid) => {
    const deleteTreeData = (list) => list.map((node) => {
      if (node.children && node.children.some(({ key }) => key === uuid)) {
        return {
          ...node,
          children: node.children.filter(({ key }) => key !== uuid),
        };
      }

      return node;
    });

    setTreeData((prev) => deleteTreeData(JSON.parse(JSON.stringify(prev))));
    clearSelectedEntity(uuid);
  };

  function updateChildren(arr, newItem, deleteItem) {
    let index = 0;
    for (const item of arr) {
      index += 1;
      if (deleteItem && item?.entity?.uuid === newItem.uuid) {
        arr.splice(index - 1, 1);
        break;
      }
      if (item?.entity?.uuid === newItem.uuid) {
        item.entity = newItem;
        break;
      }
      if (item?.children) {
        updateChildren(item?.children, newItem, deleteItem);
      }
    }
  }

  const updateTreeCallback = (item, deleteItem) => {
    const newData = JSON.parse(JSON.stringify(treeData));
    updateChildren(newData, item, deleteItem);
    setTreeData(newData);
  };
  return (
    <PageWrapper
      title={capitalizeFirstChar(t('ew.pages.entity_tree', 'entity tree'))}
    >
      <Row wrap={false}>
        <Card className="card border-blue d-flex flex-grow-1 flex-shrink-1 ">
          <div className="w-100 overflow-hidden">
            <Spin spinning={false} className="w-100">
              <Row gutter={[16, 0]} wrap={false}>
                <Col flex="none">
                  <h4 className="header-primary">
                    {capitalizeFirstChar(t('ew.headers.structure', 'structure'))}
                  </h4>
                </Col>
              </Row>
              <hr className="my-4" />
              <Row>
                <Col span={24}>
                  <EntityTreeView
                    treeData={treeData}
                    setTreeData={setTreeData}
                    selectedKeys={selectedKeys}
                    getEntityText={getEntityText}
                    getAffixText={getAffixText}
                    getEntityColor={getEntityColor}
                    onSelect={onSelectItem}
                  />
                </Col>
              </Row>
            </Spin>
          </div>
        </Card>

        {selectedEntity && (
        <EntityInfoAndPermissions
          entity={selectedEntity}
          partition={selectedPartition}
          updateTreeCallback={updateTreeCallback}
          getEntityText={getEntityText}
          getAffixText={getAffixText}
          getEntityColor={getEntityColor}
          deleteTreeNode={deleteTreeNode}
        />
        )}
      </Row>
    </PageWrapper>
  );
}

export default EntityTreePage;
