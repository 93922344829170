import LocaleConstants from '../constants';

const switchLanguage = (lang) => (
  {
    type: LocaleConstants.SWITCH_LANGUAGE,
    payload: lang,
  }
);

export default switchLanguage;
