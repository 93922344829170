import keyMirror from 'keymirror';

export const EntityConstants = keyMirror({
  FETCH_MAIN_UUID_REQUEST: null,
  FETCH_MAIN_UUID_SUCCESS: null,
  FETCH_MAIN_UUID_FAILURE: null,

  FETCH_PARTITION_REQUEST: null,
  FETCH_PARTITION_SUCCESS: null,
  FETCH_PARTITION_FAILURE: null,

  FETCH_ENTITY_REQUEST: null,
  FETCH_ENTITY_SUCCESS: null,
  FETCH_ENTITY_FAILURE: null,

  FETCH_ACTORS_REQUEST: null,
  FETCH_ACTORS_SUCCESS: null,
  FETCH_ACTORS_FAILURE: null,

  FETCH_ENTITY_PERMS_LIST_REQUEST: null,
  FETCH_ENTITY_PERMS_LIST_SUCCESS: null,
  FETCH_ENTITY_PERMS_LIST_FAILURE: null,

  FETCH_ENTITY_CHILDREN_TREE_REQUEST: null,
  FETCH_ENTITY_CHILDREN_TREE_SUCCESS: null,
  FETCH_ENTITY_CHILDREN_TREE_FAILURE: null,

  FETCH_ENTITY_TREE_REQUEST: null,
  FETCH_ENTITY_TREE_SUCCESS: null,
  FETCH_ENTITY_TREE_FAILURE: null,

  FETCH_ACTOR_REQUEST: null,
  FETCH_ACTOR_SUCCESS: null,
  FETCH_ACTOR_FAILURE: null,

  FETCH_USER_INFO_REQUEST: null,
  FETCH_USER_INFO_SUCCESS: null,
  FETCH_USER_INFO_FAILURE: null,

  FETCH_ALL_PERMS_REQUEST: null,
  FETCH_ALL_PERMS_SUCCESS: null,
  FETCH_ALL_PERMS_FAILURE: null,

  FETCH_ENTITY_PERMS_ALL_ACTORS_REQUEST: null,
  FETCH_ENTITY_PERMS_ALL_ACTORS_SUCCESS: null,
  FETCH_ENTITY_PERMS_ALL_ACTORS_FAILURE: null,

  CREATE_ENTITY_PERM_REQUEST: null,
  CREATE_ENTITY_PERM_SUCCESS: null,
  CREATE_ENTITY_PERM_FAILURE: null,

  CREATE_PARTITION_REQUEST: null,
  CREATE_PARTITION_SUCCESS: null,
  CREATE_PARTITION_FAILURE: null,

  GET_HARD_DELETE_PERM_REQUEST: null,
  GET_HARD_DELETE_PERM_SUCCESS: null,
  GET_HARD_DELETE_PERM_FAILURE: null,

  HARD_DELETE_PERM_REQUEST: null,
  HARD_DELETE_PERM_SUCCESS: null,
  HARD_DELETE_PERM_FAILURE: null
});
